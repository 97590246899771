import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
//import teamReducer from './Teams';
//import userReducer from './User';
//import analyticsReducer from '../reducers/Analytics';
//import {LOGOUT} from '../../constants/ActionTypes';


const appReducer = combineReducers({
//  teamReducer,
//  userReducer,
//  analyticsReducer
});

const rootReducer = (state, action) => {
  /* Reducers */
 // if (action.type === LOGOUT) {
    //storage.removeItem('persist:root:teamReducer');
    //storage.removeItem('persist:root:userReducer');
    // storage.removeItem('persist:otherKey')

    //state.teamReducer = undefined;
    //state.userReducer = undefined;
    // state = undefined;
 // }
  return appReducer(state, action);
};

export default rootReducer;