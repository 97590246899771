import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import Insights from '../Insights';
import Rating from './Rating';
import OverallPerformance from './OverallPerformance';
import TotalIPInvestment from './TotalIPInvestment';
import IPPerformance from './IPPerformance';
import PatentInvestment from './PatentInvestment';
import QualityInvestScatter from './PerformanceInvestmentScatter';
import NrPatents from './NrPatents';
import NrClusters from './NrClusters';
import PatentsByStatus from './PatentsByStatus';
import PatentsFiledByOffice from './PatentsFiledByOffice';
import WorldMapC from './PatentsFiledByCountry';
import ClusterDistribution from './ClusterDistribution';
import Pagination from '../Pagination';
import PercentCleantech from './PercentCleantech';
import Contact from './Contact';
import {filterInsights} from '../../../../utils/helpers';

const ExecutiveSummary = (props) => {
  const {
    funChangeSection,
    meta,
    clusters,
    patents,
    insights,
  } = props;

  if (!patents) {
    return null;
  }
  return (
      <React.Fragment>
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={7}
        >
          <Grid item xs={12}>
            <Typography component="h2" variant="h2">{meta.project_name}</Typography>
            <Typography component="h4" variant="h4">AI patent analysis for {meta.customer}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Insights insights={filterInsights(insights, 'about')} />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3" gutterBottom>Executive Summary</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                spacing={10}
            >
              {/*<Grid item xs={12} md={6}>
                <OverallPerformance patents={patents}/>
              </Grid>*/}
              <Grid item xs={12} md={6} lg={4}>
                <Rating patents={patents}/>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PercentCleantech patents={patents}/>
              </Grid>
              <Grid item xs={12} md={6}  lg={4}>
                <TotalIPInvestment patents={patents}/>
              </Grid>
              <Grid item xs={12}>
                <Insights insights={filterInsights(insights, 'executive')} />
              </Grid>
              <Grid item xs={12} md={4}>
                <NrPatents patents={patents}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <NrClusters clusters={clusters}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <PatentsByStatus patents={patents}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <IPPerformance patents={patents} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PatentInvestment patents={patents} />
              </Grid>
              <Grid item xs={12}>
                <Insights insights={filterInsights(insights, 'executive_alt')} />
              </Grid>
              <Grid item xs={12}>
                <QualityInvestScatter patents={patents}/>
              </Grid>
              <Grid item xs={12}>
                <ClusterDistribution patents={patents}/>
              </Grid>
              <Grid item xs={12} xl={6}>
                <WorldMapC patents={patents}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <PatentsFiledByOffice patents={patents}/>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Contact/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Pagination funChangeSection={funChangeSection} next="ai_evaluation" labelNext="AI Evaluation" previous={null}/>
          </Grid>
        </Grid>
      </React.Fragment>
  );
};

ExecutiveSummary.propTypes = {
  funChangeSection: PropTypes.func,
  meta: PropTypes.object,
  clusters: PropTypes.arrayOf(PropTypes.string),
  patents: PropTypes.arrayOf(PropTypes.object),
  insights: PropTypes.arrayOf(PropTypes.object),
};

export default ExecutiveSummary;
