import React from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid} from '@material-ui/core';
import styles from './Footer.module.css';

const Copyright = () => {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a color="inherit" href={"https://goodip.de"}>GoodIP GmbH</a>
        {' '}
        {new Date().getFullYear()}
      </Typography>
  );
};

const Footer = () => {
  return (
      <footer className={styles.footer}>
        <Grid item className={styles.footer_nav}>
          <ul className={styles.footer_nav_list}>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.goodip.de/terms-conditions">Terms & Conditions</a></li>
          </ul>
          <Copyright/>
        </Grid>
      </footer>
  );
};

export default Footer;