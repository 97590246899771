import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    XGrid,
    GridColTypeDef,
    GridToolbar,
    LicenseInfo,
    GridToolbarContainer,
    GridColumnsToolbarButton,
    GridFilterToolbarButton,
} from '@material-ui/x-grid';
import BoxLayout from './BoxLayout';
import {Info} from '@material-ui/icons';
import {color_blue, color_medium, color_red} from '../../../constants/Charts';
import {classifyPatentAIResult} from '../../../utils/helpers';

LicenseInfo.setLicenseKey(
    '02729790530d42eaa86b8f0f73e6b035T1JERVI6MjMyNjYsRVhQSVJZPTE2NDg5Njg0ODQwMDAsS0VZVkVSU0lPTj0x',
);

const useStyles = makeStyles(theme => ({
    high: {
        textAlign: 'center !important',
        backgroundColor: color_blue,
        color: '#ffffff',
    },
    medium: {
        textAlign: 'center !important',
        backgroundColor: color_medium,
    },
    low: {
        textAlign: 'center !important',
        backgroundColor: color_red,
        color: '#fff',
    },
    unavailable: {
        textAlign: 'center !important',
        backgroundColor: '#EFEFEF',
    },

}));

const List = (props) => {
    const classes = useStyles();

    let {
        patents,
        visible_columns,
    } = props;

    if (visible_columns === undefined) visible_columns = [];

    const aiCellClass = (value) => {
        if (value >= 0 && value < 0.25) {
            return classes.low;
        } else if (value >= 0.25 && value < 0.5) {
            return classes.medium;
        } else if (value >= 0.5 && value <= 1) {
            return classes.high;
        } else {
            return classes.unavailable;
        }
    };

    const classificationFormatter = (value) => {
        if (value === -1) {
            return 'Low';
        } else if (value === 0) {
            return 'Medium';
        } else if (value === 1) {
            return 'High';
        } else {
            return '-';
        }
    };

    const classificationCellClass = (value) => {
        if (value == -1) {
            return classes.low;
        } else if (value == 0) {
            return classes.medium;
        } else if (value == 1) {
            return classes.high;
        } else {
            return classes.unavailable;
        }
    };

    let columns = [
        {
            field: 'patent_id',
            headerName: 'Patent',
            type: 'string',
            valueGetter: (params) => {
                return params.row.meta.patent_id;
            },
            flex: 0.1,
        },
        {
            field: 'office',
            headerName: 'Office',
            hide: true,
            valueGetter: (params) => {
                return params.row.meta?.office;
            },
        },
        {
            field: 'cpc4',
            headerName: 'CPC4',
            hide: true,
            valueGetter: (params) => {
                return params.row.meta?.cpc4?.symbol;
            },
        },
        {
            field: 'title', headerName: 'Title', valueGetter: (params) => {
                return params.row.meta?.title;
            },
            flex: 0.5,
        },
        {
            field: 'goodip_family',
            headerName: 'Family',
            hide: true,
            valueGetter: (params) => {
                return params.row.meta?.goodip_family_id;
            },
        },
        {
            field: 'clusters',
            headerName: 'Clusters',
            valueGetter: (params) => {
                return params.row.meta?.clusters.join('; ');
            },
        },
        {
            field: 'assignees',
            headerName: 'Assignees',
            valueGetter: (params) => {
                return params.row.meta?.assignees.join('; ');
            },
            hide: true,
        },
        {
            field: 'application_date',
            headerName: 'Application date',
            type: 'date',
            valueGetter: (params) => {
                return params.row.dates?.application;
            },
        },
        {
            field: 'grant_date',
            headerName: 'Grant date',
            type: 'date',
            valueGetter: (params) => {
                return params.row.dates?.grant;
            },
            hide: true,
        },
        {
            field: 'expiration_date',
            headerName: 'Expiration date',
            type: 'date',
            hide: true,
            valueGetter: (params) => {
                return params.row.dates?.adjusted_expiration;
            },
        },
        {
            field: 'link',
            headerName: 'Link',
            type: 'string',
            renderCell: (params) => {
                return (<a target="_blank" rel="noopener noreferrer"
                           href={'https://patents.google.com/patent/' +
                           params.row.meta.patent_id + '/en'}>View</a>);
            },
        },
        {
            field: 'costs_office_paid',
            headerName: 'Office costs paid',
            type: 'number',
            hide: true,
            valueGetter: (params) => {
                return params.row.costs?.office?.paid;
            },
        },
        {
            field: 'costs_office_future',
            headerName: 'Office costs future',
            type: 'number',
            hide: visible_columns.indexOf('costs_office_future') === -1,
            valueGetter: (params) => {
                return params.row.costs?.office?.future;
            },
        },
        {
            field: 'costs_office_total',
            headerName: 'Office costs total',
            type: 'number',
            hide: visible_columns.indexOf('costs_office_total') === -1,
            valueGetter: (params) => {
                return params.row.costs?.office?.total;
            },
        },
        {
            field: 'costs_attorney_paid',
            headerName: 'Office costs paid',
            type: 'number',
            hide: visible_columns.indexOf('costs_attorney_paid') === -1,
            valueGetter: (params) => {
                return params.row.costs?.office?.paid;
            },
        },
        {
            field: 'costs_attorney_future',
            headerName: 'Attorney costs future',
            type: 'number',
            hide: visible_columns.indexOf('costs_attorney_future') === -1,
            valueGetter: (params) => {
                return params.row.costs?.attorney?.future;
            },
        },
        {
            field: 'costs_attorney_total',
            headerName: 'Attorney costs total',
            type: 'number',
            hide: visible_columns.indexOf('costs_attorney_total') === -1,
            valueGetter: (params) => {
                return params.row.costs?.attorney?.total;
            },
        },
        {
            field: 'costs_total',
            headerName: 'Costs total',
            type: 'number',
            valueGetter: (params) => {
                return params.row.costs?.office?.total +
                    params.row.costs?.attorney?.total;
            },
        },
        {
            field: 'ai_classification_innovative',
            headerName: 'Innovative',
            type: 'number',
            valueGetter: (params) => {
                return params.row.ai_classification?.innovative;
            },
            hide: visible_columns.indexOf('ai_classification_innovative') ===
                -1,
            valueFormatter: ({value}) => classifyPatentAIResult(value),
            cellClassName: ({value}) => aiCellClass(value),
        },
        {
            field: 'ai_classification_early',
            headerName: 'Early',
            type: 'number',
            hide: visible_columns.indexOf('ai_classification_early') === -1,

            valueGetter: (params) => {
                return params.row.ai_classification?.early;
            },
            valueFormatter: ({value}) => classifyPatentAIResult(value),
            cellClassName: ({value}) => aiCellClass(value),

        },
        {
            field: 'ai_classification_protected',
            headerName: 'Protected',
            type: 'number',
            hide: visible_columns.indexOf('ai_classification_protected') === -1,

            valueGetter: (params) => {
                return params.row.ai_classification?.protected;
            },
            valueFormatter: ({value}) => classifyPatentAIResult(value),
            cellClassName: ({value}) => aiCellClass(value),

        },
        {
            field: 'cleantech',
            headerName: 'Cleantech',
            type: 'string',
            hide: visible_columns.indexOf('cleantech') === -1,
            valueGetter: (params) => {
                return Object.keys(params.row.cleantech).join("; ");
            },
        }
    ];
    const METRICS = {
        protection: {
            ind_claims: {
                title: 'Broad independent claims',
                desciption: '',
            },
            first_claim: {
                title: 'Broad first claim',
                desciption: '',
            },
            underst_descr: {
                title: 'Understandable description',
                desciption: '',
            },
            underst_first_claim: {
                title: 'Understandable first claim',
                desciption: '',
            },
            compr_figure_details: {
                title: 'Comprehensive figures',
                desciption: '',
            },
            nr_ind_claims: {
                title: 'No. independent claims',
                desciption: '',
            },
            nr_dep_claims: {
                title: 'No. dependent claims',
                desciption: '',
            },
            neg_words_desc: {
                title: 'Negative words description',
                desciption: '',
            },
        },
        innovativeness: {
            dev_ind_claims: {
                title: 'Unchanged independent claims',
                desciption: '',
            },
            dev_first_claim: {
                title: 'Unchanged first claim',
                desciption: '',
            },
            citations_3yrs: {
                title: 'Others citing patent',
                desciption: '',
            },
            citations_3yrs_exam: {
                title: 'Examiner citing patent',
                desciption: '',
            },
        },
        earlyness: {
            similar_patents: {
                title: 'Earlier than similar patents',
                desciption: '',
            },
            technology_category: {
                title: 'Early in technology category',
                desciption: '',
            },
            citations_against_appl_exam: {
                title: 'Examiner citations against patent',
                desciption: '',
            },
            citations_applicant: {
                title: 'Own citations',
                desciption: '',
            },
        },
    };

    let metric_fields = [];
    for (const [category_name, category] of Object.entries(METRICS)) {
        for (const [metric_name, value] of Object.entries(category)) {
            const data = METRICS[category_name][metric_name];
            const field_name = `metrics__${category_name}__${metric_name}`;
            metric_fields.push({
                field: field_name,
                headerName: data.title,
                type: 'string',
                hide: visible_columns.indexOf(field_name) === -1,
                valueGetter: (params) => {
                    return params.row.metrics?.[category_name]?.[metric_name];
                },
                valueFormatter: ({value}) => classificationFormatter(value),
                cellClassName: ({value}) => classificationCellClass(value),
            });
        }
    }
    columns = columns.concat(metric_fields);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridColumnsToolbarButton/>
                <GridFilterToolbarButton/>
            </GridToolbarContainer>
        );
    }

    const title = 'Patents';
    const subtitle = <React.Fragment><Info/> Get more details by displaying additional
        columns and using the
        filters</React.Fragment>;
    const content = <Box style={{height: '800px'}}>
        <XGrid rows={patents} columns={columns} autoPageSize={true}
               pagination={true} density="compact" components={{
            Toolbar: CustomToolbar,
        }}/>
    </Box>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

List.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
    visible_columns: PropTypes.arrayOf(PropTypes.string),
};

export default List;
