import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import {
  Dashboard as OverviewIcon,
  //AccountTree as AccountTreeIcon,
  AttachMoney as MoneyIcon,
  ViewList as ViewListIcon,
  LocationSearching as ScopeOfWorkIcon,
  BlurOn as AIIcon,
  ImportContacts as ExecutiveSummaryIcon,
  BarChart as MetricsIcon,
  ExpandLess,
  ExpandMore,
  ExitToApp as LogoutIcon,
  List as TeamListIcon,
  Home as HomeIcon,
  GroupAdd as AddIcon,
  Group as TeamIcon,
  Favorite as AdviceIcon,
  Eco as CleantechIcon,
  Info as InfoIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const ListItemStyled = withStyles({
  root: {
    "& .MuiListItemIcon-root": {
      color: "inherit"
    },
    "&$selected": {
      backgroundColor: "#dfdfdf",
      color: "#030c29"
    },
    "&$selected:hover": {
      backgroundColor: "#dfdfdf",
      color: "#030c29"
    },
    "&:hover": {
      backgroundColor: "#dfdfdf",
      color: "#030c29"
    }
  },
  selected: {}
})(ListItem);


const Menu = (props) => {

  const {
    style,
    section,
    funChangeSection,
  } = props;

  return (
      <React.Fragment>
        <div className={style.toolbar}>
          <img alt="GoodIP Logo" className={style.logo}
               src={process.env.PUBLIC_URL + '/imgs/logo_light.png'}/>
        </div>

        {/*<Divider style={{backgroundColor: '#b3b4b5'}}/>*/}

        <List style={{paddingTop:0}}>
          <ListItemStyled button key='Executive summary' component="div"
                    selected={(section === 'executive' || section === undefined)}
                    onClick={() => funChangeSection('executive')}>
            <ListItemIcon><ExecutiveSummaryIcon/></ListItemIcon>
            <ListItemText primary="Executive Summary"/>
          </ListItemStyled>
          {/*<ListItemStyled button key='Overview' component="div"
                    selected={section === 'overview'}
                    onClick={() => funChangeSection('overview')}>
            <ListItemIcon><OverviewIcon/></ListItemIcon>
            <ListItemText primary="Overview"/>
          </ListItemStyled>*/}
          <ListItemStyled button key='AI Evaluation' component="div"
                    selected={section === 'ai_evaluation'}
                    onClick={() => funChangeSection('ai_evaluation')}>
            <ListItemIcon><AIIcon/></ListItemIcon>
            <ListItemText primary="AI Evaluation"/>
          </ListItemStyled>
          <ListItemStyled button key='IP investments' component="div"
                    selected={section === 'investments'}
                    onClick={() => funChangeSection('investments')}>
            <ListItemIcon><MoneyIcon/></ListItemIcon>
            <ListItemText primary="IP Investments"/>
          </ListItemStyled>
          <ListItemStyled button key='Metrics' component="div"
                    selected={section === 'metrics'}
                    onClick={() => funChangeSection('metrics')}>
            <ListItemIcon><MetricsIcon/></ListItemIcon>
            <ListItemText primary="Metrics"/>
          </ListItemStyled>
          <ListItemStyled button key='Cleantech' component="div"
                          selected={section === 'cleantech'}
                          onClick={() => funChangeSection('cleantech')}>
            <ListItemIcon><CleantechIcon/></ListItemIcon>
            <ListItemText primary="Cleantech"/>
          </ListItemStyled>
          <ListItemStyled button key='All Patents' component="div"
                    selected={section === 'patents'}
                    onClick={() => funChangeSection('patents')}>
            <ListItemIcon><ViewListIcon/></ListItemIcon>
            <ListItemText primary="All Patents"/>
          </ListItemStyled>
          <ListItemStyled button key='Background' component="div"
                          selected={section === 'background'}
                          onClick={() => funChangeSection('background')}>
            <ListItemIcon><InfoIcon/></ListItemIcon>
            <ListItemText primary="Background"/>
          </ListItemStyled>
        </List>

        <Box display="flex" flexGrow={1} flexDirection="row"
             alignItems="flex-end">
          <Box className={style.drawerBottomSection}>
            {/*<Divider style={{backgroundColor: '#b3b4b5'}}/>*/}
            <Box textAlign="center" mt={2}>
              {/*<Typography variant="body2">Support Our Mission</Typography>*/}
            </Box>
          </Box>
        </Box>
      </React.Fragment>
  );
};

Menu.propTypes = {
  style: PropTypes.object,
  section: PropTypes.string,
  funChangeSection: PropTypes.func.isRequired,
  //user: PropTypes.object.isRequired,
  //teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  //deleteSession: PropTypes.func.isRequired,
  //handleSurveysToggle: PropTypes.func.isRequired,
  //teamsExpandedState: PropTypes.bool,
};

//const mapDispatchToProps = (dispatch, ownProps) => {
//  return {deleteSession: () => dispatch(deleteSession(ownProps))};
//};

export default withRouter(Menu);