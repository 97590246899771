import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import MetricsChart from './MetricsChart';
import MetricDescriptions from '../MetricDescriptions';
import BoxLayout from '../BoxLayout';

const Early = (props) => {
    const {
        patents,
    } = props;

    const labels_early = {
        similar_patents: {
            name: 'Earlier than similar patents',
            description: 'Shows the patent\'s position in time compared to similar patents',
        },
        technology_category: {
            name: 'Early in technology category',
            description: 'Shows the patent\'s position in time in the same technology field',
        },
        citations_against_appl_exam: {
            name: 'Examiner citations against patent',
            description: 'Classifies the citations a patent received from the examiner',
        },
        citations_applicant: {
            name: 'Own citations',
            description: 'Classifies the patent owner’s own citations of the patent owner in the patent',
        },
    };

    if (!patents) {
        return null;
    }

    const title = 'Early';
    const subtitle = 'Normalized metrics indicative of innovative patents such as early stage technology patents';
    const content = <MetricsChart patents={patents} labels={labels_early}
                                  metric_category="earlyness"/>;
    const help = <MetricDescriptions metric_descriptions={labels_early}/>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

Early.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default Early;
