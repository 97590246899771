import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    Pie,
    PieChart,
    Cell,
} from 'recharts';
import {
    numFormatter,
    qualityByCluster,
    getPatentAIClassification,
    getNrPatentsAboveThreshold,
    filterExpired
} from '../../../../utils/helpers';
import HelpSection from '../HelpSection';
import BoxLayout from '../BoxLayout';
import {
    color_red,
    color_blue,
    color_numeric_metrics,
} from '../../../../constants/Charts';

const Rating = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }
    const active_patents = filterExpired(patents);
    const patent_ratings = getPatentAIClassification(active_patents);
    let average_rating = patent_ratings.reduce((a, b) => a + b) /
        patent_ratings.length;

    let rating = null;
    if (average_rating < 0.05) {
        rating = 'D';
    } else if (average_rating >= 0.05 && average_rating < 0.1) {
        rating = 'CC';
    } else if (average_rating >= 0.1 && average_rating < 0.25) {
        rating = 'C';
    } else if (average_rating >= 0.25 && average_rating < 0.35) {
        rating = 'BB';
    } else if (average_rating >= 0.35 && average_rating < 0.45) {
        rating = 'B';
    } else if (average_rating >= 0.45 && average_rating < 0.55) {
        rating = 'AA';
    } else if (average_rating >= 0.55) {
        rating = 'A';
    }

    const title = 'GoodIP rating';
    const subtitle = 'Portfolio rating';
    const content = <Typography component="div" align="center"
                                style={{
                                    fontSize: "5rem",
                                    color: color_numeric_metrics,
                                }}>
        {rating}
    </Typography>;
    const help = <Box><Table aria-label="">
        <TableHead>
            <TableRow>
                <TableCell>Class</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Risk</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell>AA</TableCell>
                <TableCell>Prime</TableCell>
                <TableCell>Lowest risk</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>A</TableCell>
                <TableCell>High grade</TableCell>
                <TableCell>Low risk</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>BB</TableCell>
                <TableCell>Upper medium grade</TableCell>
                <TableCell>Medium risk</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>B</TableCell>
                <TableCell>Lower medium grade</TableCell>
                <TableCell>Medium risk</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>CC</TableCell>
                <TableCell>Junk</TableCell>
                <TableCell>High risk</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>C</TableCell>
                <TableCell>Extreme junk</TableCell>
                <TableCell>Sustantial risk</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>D</TableCell>
                <TableCell>-</TableCell>
                <TableCell>In default</TableCell>
            </TableRow>
        </TableBody>
    </Table>
        <Typography component="div" variant="body2">
            Based on GoodIP AI models, which compare the IP performance to thousands
            of high value patents. To learn more about the AI models,
            please select Background in the navigation pane on the left side.
        </Typography>
    </Box>;
    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

Rating.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default Rating;
