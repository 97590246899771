import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
} from '@material-ui/core';
import ExecutiveSummary from '../Report/View/ExecutiveSummary/ExecutiveSummary';
import {numFormatter} from '../../utils/helpers';
//import styles from './View.module.css';
import styles from './Background.module.css';
import Pagination from '../Report/View/Pagination';

const Background = (props) => {
    const {funChangeSection} = props;
    return (
        <React.Fragment>
            <Grid container direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                  spacing={10}
            >
                <Grid item xs={12}>
                    <Typography component="h3" variant="h3" gutterBottom>
                        Background
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{height: '100%', borderRadius: '20px'}}
                           elevation={0}>
                        <Box p={3}>
                            <Typography variant="h4" component="h4"
                                        gutterBottom>GoodIP AI</Typography>
                            <Typography component="p">
                                The GoodIP AI was trained to think in a similar
                                way to how humans do.<br/><br/>
                                Learning and improving upon past
                                experiences.<br/>
                                It works by exploring thoroughly assembled
                                data-sets of litigated, licensed and innovative
                                patents and identifying complex patterns.<br/>
                                We normalized over 433 value drivers to account for characteristics in technology fields.<br/><br/>
                                On basis of new and previously unseen patterns
                                in the data-sets and value drivers,
                                interpretable statistical models can give
                                accurate predictions about: <br/><br/>
                                Is a patent early, innovative and well
                                protected?
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                          direction="row"
                          justify="space-evenly"
                          alignItems="flex-start"
                          spacing={5}
                    >
                        <Grid item xs={12} lg={4}>
                            <Paper
                                style={{height: '100%', borderRadius: '20px'}}
                                elevation={0}>
                                <Box p={3}>
                                    <Typography component="h5" variant="h4"
                                                gutterBottom>
                                        &gt; 433
                                    </Typography>
                                    <Typography component="p" variant="body1">
                                        unique patent metrics
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Paper
                                style={{height: '100%', borderRadius: '20px'}}
                                elevation={0}>
                                <Box p={3}>
                                    <Typography component="h5" variant="h4"
                                                gutterBottom>
                                        &gt; 12 Billion
                                    </Typography>
                                    <Typography component="p" variant="body1">
                                        analyzed data points
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Paper
                                style={{height: '100%', borderRadius: '20px'}}
                                elevation={0}>
                                <Box p={3}>
                                    <Typography component="h5" variant="h4"
                                                gutterBottom>
                                        &gt; 87
                                    </Typography>
                                    <Typography component="p" variant="body1">
                                        proprietary data sources
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{height: '100%', borderRadius: '20px'}}
                           elevation={0}>
                        <Box p={3}>
                            <Typography variant="h4" component="h4"
                                        gutterBottom align="center">
                                IP-driven evaluation process
                            </Typography>
                            <img alt="AI Early"
                                 className={styles.ai}
                                 src={process.env.PUBLIC_URL + '/imgs/ai.png'}/>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="flex-start"
                                spacing={5}
                            >
                                <Grid item xs={12} lg={3}>
                                    <Box display="flex"
                                         flexDirection="row"
                                         alignContent="flex-start">
                                        <div
                                            className={styles.numberCircle}>1.
                                        </div>
                                        <Box px={3}>
                                            <Typography component="p" variant="h5"
                                                        gutterBottom>
                                                Unique patent metrics
                                            </Typography>
                                            <Typography component="p"
                                                        variant="body1"
                                                        align="justify">
                                                Over 433 newly created metrics
                                                enable us
                                                to see patents from a completely
                                                different perspective and to
                                                obtain
                                                every hidden detail of them
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Box display="flex"
                                         flexDirection="row"
                                         alignContent="flex-start">
                                        <div
                                            className={styles.numberCircle}>2.
                                        </div>
                                        <Box px={3}>
                                            <Typography component="p" variant="h5"
                                                        gutterBottom>
                                                Normalized data
                                            </Typography>
                                            <Typography component="p"
                                                        variant="body1"
                                                        align="justify">
                                                There is no sense in comparing
                                                apples to
                                                pears, despite their
                                                similarities. That
                                                is why all incoming data is
                                                broken down
                                                to compare what actually
                                                matters.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={3}>

                                    <Box display="flex"
                                         flexDirection="row"
                                         alignContent="flex-start">
                                        <div
                                            className={styles.numberCircle}>3.
                                        </div>
                                        <Box px={3}>
                                            <Typography component="p" variant="h5"
                                                        gutterBottom>
                                                Artificial intelligence
                                            </Typography>
                                            <Typography component="p"
                                                        variant="body1"
                                                        align="justify">
                                                The GoodIP AI models are based
                                                on high
                                                quality data sets. After many
                                                iterations
                                                and improvements we can now
                                                proudly say
                                                that we have reached a precision
                                                and
                                                recall of over 93%.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{height: '100%', borderRadius: '20px'}}
                           elevation={0}>
                        <Box p={3}>
                            <Typography variant="h4" component="h4"
                                        gutterBottom>AI models</Typography>
                            <Grid container component="div" spacing={7}
                                  justify="space-between">
                                <Grid item xs={12} lg={3}>
                                    <Box>
                                        <Typography component="p" variant="h5"
                                                    gutterBottom>Innovative</Typography>
                                        <Typography component="p"
                                                    variant="body1"
                                                    align="justify">
                                            This AI model predicts whether a
                                            patent
                                            is innovative compared to similar
                                            patents in the same or related
                                            technology field.
                                            In consequence, the patent might be
                                            used
                                            by others. The GoodIP AI compares
                                            the
                                            patent to over 80,000 licensed
                                            patents.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Box>
                                        <Typography component="p" variant="h5"
                                                    gutterBottom>Early</Typography>
                                        <Typography component="p"
                                                    variant="body1"
                                                    align="justify">
                                            This AI model predicts whether a
                                            patent is early in the applicable
                                            technology life cycle.
                                            The GoodIP AI compares the patent to
                                            over 100,000 proven new patents.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Box>
                                        <Typography component="p" variant="h5"
                                                    gutterBottom>Protected</Typography>
                                        <Typography component="p"
                                                    variant="body1"
                                                    align="justify">
                                            This AI model predicts whether a
                                            patent is well protected (e.g., not
                                            easy to circument).
                                            The GoodIP AI compares the patent to
                                            over 40,000 patents that we asserted
                                            in litigation.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{height: '100%', borderRadius: '20px'}}
                           elevation={0}>
                        <Box p={3}>
                            <Typography variant="h4" component="h4"
                                        gutterBottom>Partners</Typography>
                            <Box display="flex"  justifyContent="space-between">
                                <img alt="Google Cloud logo"
                                     style={{height: '60px', padding: '10px'}}
                                     src={process.env.PUBLIC_URL +
                                     '/imgs/logos/google_cloud.png'}/>
                                <img alt="AWS logo"
                                     style={{height: '60px', padding: '10px'}}
                                     src={process.env.PUBLIC_URL +
                                     '/imgs/logos/aws.png'}/>
                                <img alt="EPO logo"
                                     style={{height: '60px', padding: '10px'}}
                                     src={process.env.PUBLIC_URL +
                                     '/imgs/logos/epo.png'}/>
                                <img alt="USPTO logo"
                                     style={{height: '60px', padding: '10px'}}
                                     src={process.env.PUBLIC_URL +
                                     '/imgs/logos/uspto.png'}/>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Pagination funChangeSection={funChangeSection}
                                previous="patents" labelPrevious="All patents"
                                next={null} />
                </Grid>
            </Grid>
        </React.Fragment>

    );
};

Background.propTypes = {
    funChangeSection: PropTypes.func,
    section: PropTypes.string,
    report: PropTypes.object,
    loading: PropTypes.bool,
};

export default Background;
