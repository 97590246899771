import React from 'react';
import PropTypes from 'prop-types';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Typography,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar, Cell,
} from 'recharts';
import BoxLayout from '../BoxLayout';
import {HighAvgLowXAxisTick} from '../ChartHelpers';
import {performanceByOffice} from '../../../../utils/helpers';
import {colorAIClassification} from '../../../../utils/charts';

const RegionalIPPerformance = (props) => {

  const {
    patents,
  } = props;
  if(patents === undefined) return;

  let performance_by_office = performanceByOffice(patents);
  let data = [];
  Object.entries(performance_by_office).forEach(([office, value])=> {
    data.push({
      name: office,
      performance:  value,
    });
  });

  data.sort((a, b) => (a.performance > b.performance) ? -1 : 1);
  console.log(data);
  const title = "Regional IP performance";
  const subtitle = "AI-driven IP performance rating based on GoodIP AI models per patent office";
  const content = <ResponsiveContainer width='100%' height={(44*data.length)+140}>
    <BarChart data={data} layout="vertical" margin={{left: 40, bottom: 30, right: 40}}>
      <XAxis type="number"
             stroke="black"
             label={{value: "Average performance", position: "insideBottom", dy: 15}}
             tickCount={3}
             domain={[0,1]}
             tick={<HighAvgLowXAxisTick min={0} max={1}/>}
      />
      <YAxis type="category" dataKey="name" interval={0} stroke="black" />
      <Tooltip formatter={(value) => `${Math.round((value + Number.EPSILON) * 100) / 100}`} cursor={true}/>
      <Bar dataKey="performance" fill="#42537C" barSize={40}>
        {data.map((entry, index) => (
            <Cell fill={colorAIClassification(entry.performance)}/>))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>;
  const help = null;


  return (
      <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
  );
};

RegionalIPPerformance.propTypes = {
  patents: PropTypes.arrayOf(PropTypes.object),
};

export default RegionalIPPerformance;
