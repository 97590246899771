import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    PieChart,
} from 'recharts';
import {investByCluster, numFormatter} from '../../../../utils/helpers';
import BoxLayout from '../BoxLayout';
import {color_blue} from '../../../../constants/Charts';

const PatentInvestment = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }
    const invest_cluster = investByCluster(patents);
    let chart_data = [];
    for (const [key, value] of Object.entries(invest_cluster)) {
        chart_data.push({name: key, investment: value});
    }
    chart_data.sort((a, b) => (a.investment > b.investment) ? -1 : 1);

    const title = 'Financials';
    const subtitle = 'Total patent cost per cluster including expired patents';
    const content = <ResponsiveContainer width='100%'
                                         height={(44 * chart_data.length) +
                                         140}>
        <BarChart data={chart_data} layout="vertical"
                  margin={{left: 80, bottom: 30, right: 40}}>
            <XAxis type="number"
                   label={{
                       value: 'Investment per cluster (€)',
                       position: 'insideBottom',
                       dy: 15,
                   }}
                   tickFormatter={numFormatter}
                   stroke="black"
            />
            <YAxis type="category"
                   dataKey="name"
                   interval={0}
                   stroke="black"/>
            <Bar dataKey="investment" barSize={40}
                 fill={color_blue}/>
            <Tooltip formatter={numFormatter}
                     cursor={false}/>
        </BarChart>
    </ResponsiveContainer>;
    const help = <Typography component="div" variant="body2">
        This chart shows the past and future IP investments.
    </Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

PatentInvestment.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default PatentInvestment;
