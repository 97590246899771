import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    Cell,
    Bar,
    Legend, BarChart,
} from 'recharts';
import BoxLayout from '../BoxLayout';
import {HighAvgLowXAxisTick} from '../ChartHelpers';
import {color_blue, color_green, color_yellow} from '../../../../constants/Charts';
import {filingsByYear, getMinMaxApplicationYear, qualityByYear, performanceByCluster} from '../../../../utils/helpers';
import {colorAIClassification} from '../../../../utils/charts';

const PerformanceByCluster = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }

    const averages = performanceByCluster(patents);
    let data = [];
    Object.entries(averages).forEach(([cluster, values]) => {
        values['cluster'] = cluster;

        data.push(values);
    });
    console.log(data);
    const title = 'AI ratings';
    const subtitle = 'AI ratings per cluster';
    const content = <ResponsiveContainer width="100%" minHeight="400px">
        <BarChart data={data}
                   margin={{top: 20, left: 40, bottom: 30, right: 40}}>
            <XAxis type="category"
                   dataKey="cluster"
                   stroke="black"
                   interval={0}
            />
            <Legend/>
            <YAxis type="number" interval={0} stroke="black" yAxisId="1"
                   domain={[0, 1]}
                   tickCount={3}
                   tick={<HighAvgLowXAxisTick min={0} max={1}/>}/>
            <Bar  barSize={40} yAxisId="1"  type="monotone" dataKey="early" fill={color_green}/>
            <Bar  barSize={40} yAxisId="1" type="monotone" dataKey="innovative" fill={color_blue}/>
            <Bar  barSize={40} yAxisId="1" type="monotone" dataKey="protected" fill={color_yellow}/>
            <Tooltip formatter={(value) => `${Math.round((value + Number.EPSILON) * 100) / 100}`} cursor={false}/>
        </BarChart>
    </ResponsiveContainer>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

PerformanceByCluster.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default PerformanceByCluster;
