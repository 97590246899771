import React from 'react';
import PropTypes from 'prop-types';
import {
    Hidden,
    Drawer,
    AppBar,
    Toolbar,
    IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
/* UI Components */
import Menu from './Menu';
import Footer from '../Footer';

const AppLayout = (props) => {

    const {
        theme,
        classes,
        is_index,
        section,
        funChangeSection,
        drawerToggleFun,
        drawerState,
    } = props;
    return (
        <React.Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={drawerToggleFun}
                        className={classes.navIconHide}
                    >
                        <MenuIcon/>
                    </IconButton>
                    {/*Title goes here*/}
                </Toolbar>
            </AppBar>
            {/* Drawer */}
            {/* Drawer Mobile */}
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={drawerState}
                    onClose={drawerToggleFun}
                    classes={{
                        root: classes.drawerRoot,
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{keepMounted: true}}
                >
                    <Menu
                        style={classes}
                        section={section}
                        funChangeSection={funChangeSection}
                    />
                </Drawer>
            </Hidden>
            {/* Drawer Permanent */}
            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    open
                    classes={{
                        root: classes.drawerRoot,
                        paper: classes.drawerPaper,
                    }}
                >
                    <Menu style={classes}
                          section={section}
                          funChangeSection={funChangeSection}
                    />
                </Drawer>
            </Hidden>

            {/* Content */}
            <main className={(section === null)
                ? classes.content_index
                : classes.content}>
                <div className={classes.toolbar}/>
                <div className={classes.mainContainerOpen}>
                    {props.children}
                </div>
                {/* Footer */}
                {(section !== null) && <Footer/>}
            </main>
        </React.Fragment>
    );
};

AppLayout.propTypes = {
    section: PropTypes.string,
    funChangeSection: PropTypes.func.isRequired,
    children: PropTypes.node,
    is_index: PropTypes.bool,
    theme: PropTypes.object,
    classes: PropTypes.object,
    drawerToggleFun: PropTypes.func.isRequired,
    drawerState: PropTypes.bool,
};

export default AppLayout;