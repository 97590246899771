import _ from 'lodash';

let filterInsights = (insights, category) => {
    return _.filter(insights, {category: category});

};

let classifyPatentAIResult = (result) => {
    if (result >= 0 && result < 0.25) {
        return 'low';
    }
    if (result >= 0.25 && result < 0.5) {
        return 'medium';
    }
    if (result >= 0.5 && result <= 1) {
        return 'high';
    }
    return null;
};

let avgPatentAIResult = (ai_classification) => {
    const classifications = Object.values(ai_classification);
    if(classifications.length > 0) {
        const avg = classifications.reduce((a, c) => a + c) / classifications.length;
        return avg;
    }
    return null;
};

let filterExpired = (patents) => {
    const today = new Date();
    let non_expired = patents.reduce((non_expired, patent) => {
        const expiration_date = new Date(patent.dates?.adjusted_expiration);
        if(isNaN(expiration_date) || expiration_date > today) {
            non_expired.push(patent);
        }
        return non_expired;
    }, []);
    return non_expired;
};

let rescaleAvgPatentAIResult = (avg_ai_result) => {
    const newMin = 0;
    const newMax = 1;
    const oldMin = 0;
    const oldMax = 3;
    return (((avg_ai_result - oldMin) * (newMax - newMin)) /
        (oldMax - oldMin)) + newMin;
};

let getMinMaxApplicationYear = (patents) => {
    const foo = patents.reduce((data, patent) => {
        const application_date = patent.dates?.application;
        if(application_date !== null && application_date !== undefined) {
            const d = new Date(application_date);
            if(!isNaN(d)) {
            const year = d.getFullYear();
            if (!data.hasOwnProperty(year)) {
                data.push(year);
            }
            }
        }
        return data;
    }, []);
    return [Math.min(...foo), Math.max(...foo)];
}

let filingsByYear = (patents) => {
    const foo = patents.reduce((data, patent) => {
        const application_date = patent.dates?.application;
        if(application_date !== null && application_date !== undefined) {
            const d = new Date(application_date);
            if(!isNaN(d)) {
                const year = d.getFullYear();
                if (!data.hasOwnProperty(year)) {
                    data[year] = 0;
                }
                data[year]++;
            }
        }
        return data;
    }, {});
    return foo;
}


let getPatentAIClassification = (patents) => {
    return patents.reduce((data, patent) => {
        const classifications = [
            patent.ai_classification.early, patent.ai_classification.innovative,
            patent.ai_classification.protected];
        data.push(aiAverage(classifications));
        return data;
    }, []);
};

let getNrPatentsAboveThreshold = (patents, threshold) => {
    const classifications = getPatentAIClassification(patents);
    return _.filter(classifications,
        function(o) { if (o > threshold) return o; }).length;
};

export let numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K';
    } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num <= 999) {
        return num;
    }
};

let aiClassification = (value) => {
    if (value >= 0 && value < 0.5) {
        return 0;
    } else if (value >= 0.5 && value <= 1) {
        return 1;
    } else {
        return NaN;
    }
};

let aiAverage = (classifications) => {
    const data = classifications.reduce((data, classification) => {
        if (!isNaN(classification)) {
            data.valid++;
            data.sum += classification;
        }
        return data;
    }, {valid: 0, sum: 0});
    return data.sum / data.valid;
};

export let qualityByCluster = (patents) => {
    let individual_values = patents.reduce((data, patent) => {
        let classifications = [
            aiClassification(patent.ai_classification.early),
            aiClassification(patent.ai_classification.innovative),
            aiClassification(patent.ai_classification.protected)];
        let classification_average = aiAverage(classifications);
        patent.meta?.clusters.forEach((cluster) => {
            try {
                if (!data.hasOwnProperty(cluster)) {
                    data[cluster] = [];
                }
                data[cluster].push(classification_average);
            } catch (e) {}
        });
        return data;
    }, {});

    let averages = {};
    for (const [key, value] of Object.entries(individual_values)) {
        averages[key] = value.reduce(function(pv, cv) { return pv + cv; }, 0) /
            value.length;
    }
    return averages;
};


let performanceByCluster = (patents) => {
    let individual_values = patents.reduce((data, patent) => {
        //patent.ai_classification.early,
        //patent.ai_classification.innovative,
        //patent.ai_classification.protected
        const clusters = patent.meta?.clusters || [];
        clusters.forEach((cluster) => {
            if (!data.hasOwnProperty(cluster)) {
                data[cluster] = {early: [], innovative: [], protected: []};
            }
            data[cluster]['early'].push(patent.ai_classification?.early);
            data[cluster]['innovative'].push(patent.ai_classification?.innovative);
            data[cluster]['protected'].push(patent.ai_classification?.protected);
        });
        return data;
    }, {});
    const averages = {};
    for (const [key, value] of Object.entries(individual_values)) {
        averages[key] = {};
        ["early", "innovative", "protected"].forEach(model => {
            if(value[model].length > 0) {
                averages[key][model] = value[model].reduce(function(pv, cv) { return pv + cv; },
                    0) / value[model].length;
            }
        });
    }
    return averages;
};

let qualityByYear = (patents) => {
    let individual_values = patents.reduce((data, patent) => {
        const application_date = patent.dates?.application;
        if (application_date !== null && application_date !== undefined) {
            const d = new Date(application_date);
            if (!isNaN(d)) {
                const year = d.getFullYear();
                if (!data.hasOwnProperty(year)) {
                    data[year] = {early: [], innovative: [], protected: []};
                }
                data[year]['early'].push(patent.ai_classification.early);
                data[year]['innovative'].push(
                    patent.ai_classification.innovative);
                data[year]['protected'].push(
                    patent.ai_classification.protected);
            }
        }
        return data;
    }, {});
    const averages = {};
    for (const [key, value] of Object.entries(individual_values)) {
        averages[key] = {};
        ["early", "innovative", "protected"].forEach(model => {
            if(value[model].length > 0) {
                averages[key][model] = value[model].reduce(function(pv, cv) { return pv + cv; },
                    0) / value[model].length;
            }
        });
    }
    return averages;
};


let getInvestByPerformance = (patents) => {
    const data = patents.reduce((data, patent) => {
        let avgAIResult = avgPatentAIResult(patent.ai_classification);
        let classification = classifyPatentAIResult(avgAIResult);
        if (classification != null) {
            data[classification] += patent.costs?.total || 0;
        }
        return data;
    }, {high: 0, medium: 0, low: 0});
    let transposed = ['low', 'medium', 'high'].map(name => {
        if (data.hasOwnProperty(name)) {
            return {classification: name, investment: data[name]};
        }
    });
    return transposed;
};

let performanceByOffice = (patents) => {
    let individual_values = patents.reduce((data, patent) => {
        const office = patent.meta.office;
        let avgAIResult = avgPatentAIResult(patent.ai_classification);
        if (!data.hasOwnProperty(office)) {
            data[office] = [];
        }
        data[office].push(avgAIResult);
        return data;
    }, {});

    let averages = {};
    for (const [key, value] of Object.entries(individual_values)) {
        if (value.length > 0) {
            averages[key] = value.reduce(function(pv, cv) { return pv + cv; },
                0) / value.length;
        }
    }
    return averages;
};

let getStatusDistribution = (patents) => {
    const today = new Date();
    let data = patents.reduce((data, patent) => {
        const expiration_date = new Date(patent.dates?.adjusted_expiration);
        if(!isNaN(expiration_date) && expiration_date < today) {
            data.expired += 1;
            return data;
        }
        if(patent.dates?.grant !== "None") {
            data.granted += 1;
        } else {
            data.applications += 1;
        }
        return data;
    }, {applications:0, granted:0, expired:0});
    return data;
};

export let investByCluster = (patents) => {
    return patents.reduce((data, patent) => {
        patent.meta?.clusters.forEach((cluster) => {
            if (!data.hasOwnProperty(cluster)) {
                data[cluster] = 0;
            }
            data[cluster] += patent.costs?.total || 0;
        });
        return data;
    }, {});
};

let getPerformanceData = (patents) => {
    let data = patents.reduce((result, patent) => {
        try {
            Object.entries(patent.ai_classification).
                forEach(([model_name, value]) => {
                    if (!result.hasOwnProperty(model_name)) {
                        result[model_name] = {low: 0, medium: 0, high: 0};
                    }
                    let ai_classification = classifyPatentAIResult(value);
                    if (ai_classification != null) {
                        result[model_name][ai_classification]++;
                    }
                });
        } catch (e) {}
        return result;
    }, {});

    let percentages = {};
    Object.entries(data).forEach(([model_name, value]) => {
        const total = value.low + value.medium + value.high;
        if (total === 0) {
            percentages[model_name] = {high: 0, medium: 0, low: 0};
        } else {
            percentages[model_name] = {
                high: (value.high / total) * 100,
                medium: (value.medium / total) * 100,
                low: (value.low / total) * 100,
            };
        }
    });

    return percentages;
};

export {
    aiClassification,
    aiAverage,
    getPerformanceData,
    performanceByOffice,
    filterInsights,
    getPatentAIClassification,
    getNrPatentsAboveThreshold,
    classifyPatentAIResult,
    getInvestByPerformance,
    getStatusDistribution,
    filingsByYear,
    getMinMaxApplicationYear,
    filterExpired,
    qualityByYear,
    performanceByCluster
};