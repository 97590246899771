import React from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    ZAxis,
    ScatterChart,
    Scatter,
    CartesianGrid,
    LabelList,
} from 'recharts';
import {
    investByCluster,
    numFormatter,
    qualityByCluster,
    filterExpired
} from '../../../../utils/helpers';
import {HighAvgLowXAxisTick} from '../ChartHelpers';
import {color_red, color_medium, color_blue} from '../../../../constants/Charts';
import BoxLayout from '../BoxLayout';

const QualityInvestScatter = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }

    const active_patents = filterExpired(patents);
    const cluster_invest = investByCluster(active_patents);
    const cluster_quality = qualityByCluster(active_patents);

    let chart_data = [];
    for (const [cluster, invest] of Object.entries(cluster_invest)) {
        let quality = NaN;
        try {
            quality = cluster_quality[cluster];
        } catch (e) {}
        chart_data.push(
            {name: cluster, investment: invest, quality: quality});
    }
    chart_data.sort((a, b) => (a.investment > b.investment) ? -1 : 1);


    const title = "Performance vs. investment";
    const subtitle = "AI-driven patents performance vs. investments per cluster";
    const content = <ResponsiveContainer width='100%'
                                         height={500}>
        <ScatterChart
            margin={{
                top: 20,
                right: 100,
                bottom: 20,
                left: 50,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" verticalFill={[
                chroma(color_red).alpha(0.4),
                chroma(color_medium).alpha(0.4),
                chroma(color_blue).alpha(0.4),
                chroma(color_blue).alpha(0.4)]}/>
            <YAxis type="number"
                   dataKey="investment"
                   name="Investment"
                   unit="€"
                   scale="sqrt"
                   tickFormatter={numFormatter}
                   stroke="black"/>
            <XAxis type="number"
                   dataKey="quality"
                   name="Average performance"
                   label={{
                       value: 'Performance',
                       position: 'insideBottom',
                       dy: 15,
                   }}
                   stroke="black"
                   domain={[0, 1]}
                   tickCount={5}
                   tick={<HighAvgLowXAxisTick min={0} max={1}/>}
            />
            <ZAxis range={[300, 300]}
                   stroke="black"/>
            <Tooltip
                formatter={(value) => new Intl.NumberFormat(
                    'en').format(value)}/>
            <Scatter name="Clusters"
                     data={chart_data}
                     fill="black" shape="circle">
                <LabelList position="bottom"
                           dataKey="name"
                           offset={10}
                           style={{
                               fontSize: '100%',
                               fill: 'black',
                           }}/>
            </Scatter>
        </ScatterChart>
    </ResponsiveContainer>;
    const help = <Typography component="div" variant="body2">
        This chart illustrates how IP performance correlates with invested capital.
    </Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

QualityInvestScatter.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default QualityInvestScatter;
