import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
} from '@material-ui/core';
import List from '../List';
import Pagination from '../Pagination';

const AllPatents = (props) => {
    const {
        funChangeSection,
        patents
    } = props;

    if (!patents) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={7}
            >
                <Grid item xs={12}>
                    <Typography component="h3" variant="h3" gutterBottom>All patents</Typography>
                </Grid>
                <Grid item xs={12}>
                    <List patents={patents}/>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Pagination funChangeSection={funChangeSection}
                            previous="cleantech" labelPrevious="Cleantech"
                            next="background" labelNext="Background" />
            </Grid>
        </React.Fragment>
    );
};

AllPatents.propTypes = {
    funChangeSection: PropTypes.func,
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default AllPatents;
