import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import _ from 'lodash';
import {numFormatter} from '../../../../utils/helpers';
import BoxLayout from '../BoxLayout';
import {color_numeric_metrics} from '../../../../constants/Charts';

const TotalIPInvestment = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }

    const totalCosts = _.sumBy(patents, function(patent) {
        return patent.costs.total;
    });

    const title = 'IP investment';
    const subtitle = 'Total amount of investments in ' + patents.length + ' patents';
    const content = <Typography component="p" align="center"
                                style={{
                                    color: color_numeric_metrics,
                                    fontSize: "5rem",
                                }}>€ {numFormatter(
        totalCosts)}</Typography>;
    const help = <Typography component="div" variant="body2">
        The past and future IP investment for the patents taking into account
        patent office fees and average patent attorney fees.
        As patenting is costly, patent owners file a patent application if they
        expect that there is a potential market for the invention in a given
        country.
    </Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

TotalIPInvestment.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default TotalIPInvestment;
