import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import Insights from '../Insights';
import Clusters from '../Clusters';
import PastInvestment from './PastInvestment';
import FutureInvestment from './FutureInvestment';
import RegionalInvestment from './RegionalInvestment';
import InvestmentByPerformance from './InvestmentByPerformance';
import InvestmentPerCountry from './InvestmentPerCountry';
import List from '../List';
import Pagination from '../Pagination';

const Investment = (props) => {
  const {
    patents,
    insights,
    clusters,
    active_filter,
    funUpdateFilter,
    reportDate,
    funChangeSection
  } = props;

  if (!patents) {
    return null;
  }

  const cols_to_show = ["costs_office_future",
    "costs_office_total",
    "costs_attorney_paid",
    "costs_attorney_future",
    "costs_attorney_total"];


  return (
      <React.Fragment>
        <Box pb={5}>
        <Typography component="h3" variant="h3" gutterBottom>IP Investments</Typography>
        </Box>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={10}
        >
          <Grid item xs={12}>
            <Insights insights={insights}/>
          </Grid>

          <Grid item xs={12}>
            <Clusters clusters={clusters} funUpdateFilter={funUpdateFilter} active_filter={active_filter} nrPatentsSelected={patents.length}/>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <PastInvestment patents={patents} reportDate={reportDate}/>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FutureInvestment patents={patents}/>
          </Grid>
          <Grid item xs={12} lg={6}>
            <RegionalInvestment patents={patents}/>
          </Grid>
          <Grid item xs={12} lg={4}>
            <InvestmentByPerformance patents={patents}/>
          </Grid>
          <Grid item xs={12}>
            <InvestmentPerCountry patents={patents}/>
          </Grid>
          <Grid item xs={12}>
            <List patents={patents} visible_columns={cols_to_show}/>
          </Grid>
          <Grid item xs={12}>
            <Pagination funChangeSection={funChangeSection}
                        previous="ai_evaluation" labelPrevious="AI Evaluation"
                        next="metrics" labelNext="Metrics" />
          </Grid>
        </Grid>
      </React.Fragment>
  );
};

Investment.propTypes = {
  funChangeSection: PropTypes.func,
  reportDate: PropTypes.string,
  insights: PropTypes.arrayOf(PropTypes.object),
  patents: PropTypes.arrayOf(PropTypes.object)
};

export default Investment;
