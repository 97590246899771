import React from 'react';
import PropTypes from 'prop-types';
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Paper,
    Typography,
    Grid,
    LinearProgress,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
} from 'recharts';
import {color_blue} from '../../../../constants/Charts';
import BoxLayout from '../BoxLayout';

const PatentsFiledByOffice = (props) => {

    const {
        patents,
    } = props;
    if (patents === undefined) return;

    let counts = patents.reduce((p, c) => {
        const office = c.meta.office;
        if (!p.hasOwnProperty(office)) {
            p[office] = {name: office, count: 0};
        }
        p[office]['count']++;
        return p;
    }, {});

    var values = Object.keys(counts).map(function(key) {
        return counts[key];
    });
    values.sort((a, b) => (a.count > b.count) ? -1 : 1);

    const title = 'Patent office overview';
    const subtitle = 'Patent offices in which patents were filed';
    const content = <ResponsiveContainer width='100%'
                                         height={(44 * values.length) + 140}>
        <BarChart data={values} layout="vertical"
                  margin={{left: 40, bottom: 30, right: 40}}>
            <XAxis type="number" stroke="black" label={{
                value: 'Patents by office filed',
                position: 'insideBottom',
                dy: 15,
            }}/>
            <YAxis type="category" dataKey="name" interval={0}
                   stroke="black"/>
            <Tooltip/>
            <Bar dataKey="count" fill={color_blue}
                 barSize={40}/>
        </BarChart>
    </ResponsiveContainer>;
    const help = <Typography variant="body1" component="div">
        The patent owner can file a patent application in a national patent
        office, the European Patent Office (EPO) or the World Intellectual
        Property Organization (WIPO). A European Patent (EP) is only valid in
        the European countries in which the patent owner validated it after
        grant. A worldwide patent application (WO) is only valid in the
        countries in which the patent owner filed a subsequent patent
        application.
    </Typography>;

    return (
        <BoxLayout title={title}
                   subtitle={subtitle}
                   content={content}
                   helpContent={help}/>
    );
};

PatentsFiledByOffice.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default PatentsFiledByOffice;
