import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';

import Clusters from '../Clusters';
import List from '../List';
import Early from './Early';
import Innovative from './Innovative';
import Protected from './Protected';
import Pagination from '../Pagination';


const Metrics = (props) => {
  const {
    patents,
    clusters,
    active_filter,
    funUpdateFilter,
    funChangeSection
  } = props;

  if (!patents) {
    return null;
  }

  const cols_to_show = ["metrics__protection__ind_claims",
    "metrics__protection__first_claim",
    "metrics__protection__underst_descr",
    "metrics__protection__underst_first_claim",
    "metrics__protection__compr_figure_details",
    "metrics__protection__nr_ind_claims",
    "metrics__protection__nr_dep_claims",
    "metrics__protection__neg_words_desc",
    "metrics__innovativeness__dev_ind_claims",
    "metrics__innovativeness__dev_first_claim",
    "metrics__innovativeness__citations_3yrs",
    "metrics__innovativeness__citations_3yrs_exam",
    "metrics__earlyness__similar_patents",
    "metrics__earlyness__technology_category",
    "metrics__earlyness__citations_against_appl_exam",
    "metrics__earlyness__citations_applicant"];

  return (
      <React.Fragment>
        <Box pb={5}>
        <Typography component="h3" variant="h3"
                    gutterBottom>Metrics</Typography>
        </Box>
        <Box pb={5}>
          <Typography component="h4" variant="h4" gutterBottom>
            IP expert metrics
          </Typography>
          <Box color={"#4a4a4a"} my={3}>
            <Typography variant="body1" component="div" align="justify">
              To make the results of the AI model more comprehensible,
            </Typography>
          </Box>
        </Box>
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={10}
        >
          <Grid item xs={12}>
            <Clusters clusters={clusters} active_filter={active_filter}
                      funUpdateFilter={funUpdateFilter} nrPatentsSelected={patents.length}/>
          </Grid>
          <Grid item xs={12}>
            <Early patents={patents}/>
          </Grid>
          <Grid item xs={12}>
            <Innovative patents={patents}/>
          </Grid>
          <Grid item xs={12}>
            <Protected patents={patents}/>
          </Grid>
          <Grid item xs={12}>
            <List patents={patents} visible_columns={cols_to_show}/>
          </Grid>
          <Grid item xs={12}>
            <Pagination funChangeSection={funChangeSection}
                        previous="investments" labelPrevious="IP Investments"
                        next="cleantech" labelNext="Cleantech" />
          </Grid>
        </Grid>
      </React.Fragment>
  );
};

Metrics.propTypes = {
  funChangeSection: PropTypes.func,
  active_filter: PropTypes.array,
  funUpdateFilter: PropTypes.func,
  clusters: PropTypes.array,
  patents: PropTypes.arrayOf(PropTypes.object)
};

export default Metrics;
