import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import "@fontsource/old-standard-tt";
import "@fontsource/poppins";
import "@fontsource/montserrat";
import {red, blue} from '@material-ui/core/colors';

let theme = createMuiTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 16,
    fontFamily: 'Montserrat, Arial',
    h1: {
      fontFamily: "Montserrat",
      color: '#1f263e',
      fontWeight: 'bold',
      fontSize: '3rem'
    },
    h3: {
      fontFamily: "Montserrat",
      fontWeight: 'bold',
      color: 'black'
    },
    h4: {
      fontFamily: "Montserrat",
      color: 'black',
      fontWeight:'bold'
    },
  },
  paper: { borderRadius: "2%!important", borderColor: "#000", padding: 50 },
  palette: {
    primary: {
      light: '#677596',
      main: '#42537C',
      dark: '#2e3a56',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffa700',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    appBar: {
      backgroundColor: '#fff',
    },
  },

  overrides: {
    MuiTableRow: {
      root: {
        '& td': {
          borderBottom: 0,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme, {factor:2});

export default theme;
