import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
} from '@material-ui/core';
import {Email, Phone} from '@material-ui/icons';
import {
    color_red,
    color_blue,
    color_numeric_metrics,
} from '../../../../constants/Charts';

const Contact = (props) => {
    const {} = props;

    return (
        <Box py={15}>
            <Grid container>
                <Grid item xs={6}>
                    <img alt="Dr. Bastian July" style={{
                        height: '150px',
                        float: 'right',
                        marginRight: '30px',
                        marginTop: '10px',
                    }}
                         src={process.env.PUBLIC_URL +
                         '/imgs/team/bastian.png'}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h4" variant="h4">Contact</Typography>
                    <Typography component="div" variant="body1">
                        Your contact person for further questions regarding this
                        report is:
                    </Typography>
                    <Box pt={2}>
                        <Typography component="div"
                                    variant="body1"
                                    style={{fontWeight: 'bold'}}>
                            Dr. Bastian July
                        </Typography>
                        <Typography component="div" variant="body1">
                            Co-founder & CEO<br/>
                            LL.M. (Boston University)
                        </Typography>
                    </Box>
                    <Typography component="a" variant="body1"
                                href="tel:+4915254019690" style={{textDecoration: "none", color: "black"}}>
                        <Typography component="span" style={{
                            verticalAlign: 'middle',
                            display: 'inline-flex',
                        }}><Phone
                            style={{color: 'black'}}/>&nbsp; +49 152 540 19690</Typography>
                    </Typography><br/>
                    <Typography component="a" variant="body1"
                                href="mailto:bastian.july@goodip.de" style={{textDecoration: "none", color: "black"}}>
                        <Typography component="span" style={{
                            verticalAlign: 'middle',
                            display: 'inline-flex',
                        }}><Email
                            style={{color: 'black'}}/>&nbsp; bastian@goodip.de</Typography>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

Contact.propTypes = {};

export default Contact;
