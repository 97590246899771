import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    Pie,
    PieChart,
    Cell,
    Legend,
    Tooltip
} from 'recharts';
import BoxLayout from '../BoxLayout';
import {get_color_range_greens} from '../../../../constants/Charts';

const CleantechTopicDistribution = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }

    let tech_dist = patents.reduce((data, patent)=>{
        Object.keys(patent.cleantech).forEach(cluster => {
            if(cluster !== undefined && !data.hasOwnProperty(cluster)) {
                data[cluster] = 0;
            }
            data[cluster]++;
        });
        return data;
    }, {});
    let data = [];
    Object.keys(tech_dist).forEach(cluster => {
        data.push({name: cluster, value: tech_dist[cluster]});
    });
    const colors = get_color_range_greens(data.length).reverse();

    const title = 'Cleantech topics';
    const subtitle = 'Distribution of cleantech technology categories';
    const content = <ResponsiveContainer width='100%' height={400}>
        <PieChart  margin={{left: 40, bottom: 0, right: 40}}>
            <Legend layout="horizontal" verticalAlign="bottom" align="left"/>
            <Pie
                dataKey="value"
                data={data}
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="150"
                isAnimationActive={true}
            >
                {data.map((cluster, index) => {
                    return <Cell fill={colors[index]} />
                })};
            </Pie>
            <Tooltip cursor={false}/>
        </PieChart>
    </ResponsiveContainer>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

CleantechTopicDistribution.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default CleantechTopicDistribution;
