import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
//import {PAGE_NAME} from '../constants';

const TitleComponent = ({title}) => {
  const defaultTitle = '';
  return (
      <Helmet>
        <title>{title
            ? ':: ' + title
            : defaultTitle}</title>
      </Helmet>
  );
};

TitleComponent.propTypes = {
  title: PropTypes.string,
};

export default TitleComponent;