import chroma from 'chroma-js';



//const color_red = 'rgba(255,0,70,0.4)';
//const color_medium = 'rgba(255,211,47,0.4)';
//const color_blue = 'rgba(94,77,185,0.7)';

/*const color_red = '#E76067';
const color_medium = '#F5CCCC';
const color_blue = '#77ABBD';
*/

const color_red = 'rgba(231,96,103,0.9)';
const color_medium = 'rgba(245,204,204,0.9)';
const color_blue = 'rgba(49,88,122,0.9)';
const color_green = 'rgba(12,102,51,0.9)';
const color_yellow = 'rgba(170,140,40,0.9)';
const foo = '#CDEAE5';

const color_numeric_metrics = color_blue;

const get_color_range = (nr_colors) => {
    return chroma.scale([color_red, color_medium, color_blue]).colors(nr_colors);
}
const get_color_range_greens = (nr_colors) => {
    return chroma.scale(["#409A81", "#6FC853", "#B9DE5C"]).colors(nr_colors);
}

export {color_blue, color_red, color_medium, color_green, color_yellow, color_numeric_metrics, get_color_range,get_color_range_greens};