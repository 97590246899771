import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class ClusterFilter extends React.Component {
    constructor(props) {
        super(props);
        const filter = [this.props.clusters[0]];
        this.state = {
            active_filter: filter,
            patents: this.filterPatents(this.props.patents, filter),
        };
    }

    filterPatents = (patents, active_filter) => {
        if (active_filter.length === 0)
            return [];
        return patents.filter(function(patent) {
            return active_filter.some(
                item => patent.meta.clusters.includes(item));
        });
    };

    updateFilter = (cluster) => {
        let new_filter = [];
        if (this.state.active_filter.indexOf(cluster) >= 0) {
            new_filter = this.state.active_filter.filter(
                function(filter_cluster) {
                    return filter_cluster !== cluster;
                });
        } else {
            new_filter = [...this.state.active_filter, cluster];
        }
        const new_patents = this.filterPatents(this.props.patents, new_filter);
        this.setState({active_filter: new_filter, patents: new_patents});
    };

    componentDidMount() {
        //const {project_id, access_token} = this.props.match.params;
        //this.loadData(project_id, access_token);
    }

    render() {

        return (
            <div>{React.cloneElement(this.props.children, {
                patents: this.state.patents,
                active_filter: this.state.active_filter,
                clusters: this.props.clusters,
                funUpdateFilter: this.updateFilter,
            })}</div>
        );
    }
}

ClusterFilter.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object).isRequired,
    clusters: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.object,
};

export default withRouter(ClusterFilter);