import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,

} from '@material-ui/core';
import {ExpandMore, HelpOutline} from '@material-ui/icons';

const HelpSection = (props) => {
    const {
        children,
    } = props;

    if (!children) {
        return null;
    }

    return (
        <Box bottom style={{display: 'flex', width: '100% !important'}}>
            <Accordion elevation={0} style={{width: '100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMore style={{color: "#C7C7C7"}}/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <HelpOutline style={{color: "#C7C7C7"}}/> &nbsp;
                    <Typography component="span" variant="body1" style={{color: "#c7c7c7"}}>
                        Additional information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

HelpSection.propTypes = {
    children: PropTypes.arrayOf(PropTypes.object),
};

export default HelpSection;