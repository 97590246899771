import React from 'react';
import PropTypes from 'prop-types';
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    Legend,
} from 'recharts';
import BoxLayout from '../BoxLayout';
import {
    color_blue,
    color_green,
    color_yellow,
} from '../../../../constants/Charts';
import {
    filingsByYear,
    getMinMaxApplicationYear,
} from '../../../../utils/helpers';

const CleantechFilingDate = (props) => {
    const {
        patents,
        cleantech_patents,
    } = props;

    if (!patents) {
        return null;
    }
    const dist_cleantech = filingsByYear(cleantech_patents);
    const dist_all = filingsByYear(patents);
    const [minYear, maxYear] = getMinMaxApplicationYear(patents);
    const all_years = Array(maxYear - minYear).
        fill().
        map((d, i) => i + minYear) || [];
    let data = [];
    all_years.forEach((year) => {
        let year_data = {year: year};
        if (dist_all.hasOwnProperty(year)) {
            year_data['all innovations'] = dist_all[year];
        } else {
            year_data['all innovations'] = 0;
        }
        if (dist_cleantech.hasOwnProperty(year)) {
            year_data['cleantech'] = dist_cleantech[year];
        } else {
            year_data['cleantech'] = 0;
        }
        data.push(year_data);
    });
    const title = 'Cleantech development';
    const subtitle = 'Filing year of clean innovations';
    const content = <ResponsiveContainer width="100%" minHeight="400px"
                                         maxHeight="400px">
        <LineChart data={data}
                   margin={{top: 20, left: 40, bottom: 30, right: 40}}>
            <XAxis type="number"
                   dataKey="year"
                   stroke="black"
                   interval={0}
                   domain={[minYear, maxYear]}
            />
            <YAxis type="number" interval={0} stroke="black" yAxisId="1"/>
            <Line yAxisId="1" type="monotone" dataKey="all innovations"
                  stroke={color_blue} strokeDasharray="3 3"/>
            <Line yAxisId="1" type="monotone" dataKey="cleantech"
                  stroke={color_green}/>
            <Legend/>
            <Tooltip/>
        </LineChart>
    </ResponsiveContainer>;
    const help = null;
    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

CleantechFilingDate.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
    cleantech_patents: PropTypes.arrayOf(PropTypes.object),

};

export default CleantechFilingDate;
