import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    Cell,
    Legend, BarChart,
} from 'recharts';
import BoxLayout from '../BoxLayout';
import {HighAvgLowXAxisTick} from '../ChartHelpers';
import {color_blue, color_green, color_yellow} from '../../../../constants/Charts';
import {filingsByYear, getMinMaxApplicationYear, qualityByYear} from '../../../../utils/helpers';
import {colorAIClassification} from '../../../../utils/charts';

const PerformanceOverTime = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }

    const [minYear, maxYear] = getMinMaxApplicationYear(patents);
    let all_years = []
    if(isFinite(minYear) && isFinite(maxYear)) {
        all_years = Array(maxYear - minYear).fill().map((d, i) => i + minYear) || [];
    }
    const averages = qualityByYear(patents);
    let data = [];
    all_years.forEach((year)=>{
        let year_data = {year: year};
        if(averages.hasOwnProperty(year)) {
            year_data['early'] = averages[year]['early'];
            year_data['innovative'] = averages[year]['innovative'];
            year_data['protected'] = averages[year]['protected'];
        } else {
            year_data['all'] = 0;
        }
        data.push(year_data);
    });
    const title = 'AI ratings';
    const subtitle = 'AI ratings over time';
    const content = <ResponsiveContainer width='100%' minHeight="400px">
        <LineChart data={data}
                   margin={{top: 20, left: 40, bottom: 30, right: 40}}>
            <XAxis type="category"
                   dataKey="year"
                   stroke="black"
                   domain={[minYear, maxYear]}
            />
            <Legend/>
            <YAxis type="number" interval={0} stroke="black" yAxisId="1"
                   domain={[0, 1]}
                   tickCount={3}
                   tick={<HighAvgLowXAxisTick min={0} max={1}/>}/>
            <Line  yAxisId="1"  type="monotone" dataKey="early" stroke={color_green} connectNulls/>
            <Line  yAxisId="1" type="monotone" dataKey="innovative" stroke={color_blue} connectNulls/>
            <Line  yAxisId="1" type="monotone" dataKey="protected" stroke={color_yellow} connectNulls/>
            <Tooltip formatter={(value) => `${Math.round((value + Number.EPSILON) * 100) / 100}`} cursor={true}/>
        </LineChart>
    </ResponsiveContainer>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

PerformanceOverTime.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default PerformanceOverTime;
