import React from 'react';
import PropTypes from 'prop-types';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {color_blue, color_medium, color_red} from '../../../../constants/Charts';

const MetricsChart = (props) => {
  const {
    metric_category,
    patents,
    labels
  } = props;

  if (!patents || !metric_category || !labels) {
    return null;
  }

  let data = patents.reduce((data, patent) => {
    const early = patent.metrics[metric_category];
    Object.keys(early).map(function(key) {
      if (!data.hasOwnProperty(key)) {
        data[key] = {name: key, bad: 0, average: 0, good: 0};
      }
      switch (early[key]) {
        case -1:
          data[key]['bad']++;
          break;
        case 0:
          data[key]['average']++;
          break;
        case 1:
          data[key]['good']++;
          break;
      }
    });
    return data;
  }, {});

  let relative_data = [];
  Object.keys(data).map(function(key) {
    let dic = data[key];
    let sum = dic.bad + dic.average + dic.good;
    relative_data.push({
      name: labels[dic.name].name,
      bad: ((dic.bad / sum) * 100),
      average: ((dic.average / sum) * 100),
      good: ((dic.good / sum) * 100),
    });
  });

  return (
      <React.Fragment>
            <ResponsiveContainer  width="100%" height={relative_data.length*44+100}>
              <BarChart
                  layout="vertical"
                  data={relative_data}
                  barGap={2}
                  margin={{
                    bottom: 40,
                    right: 40,
                    left: 200,
                  }}
              >
                <XAxis type="number"
                       domain={[0, 100]}
                       tickCount={12}
                       tickFormatter={(value) => {return Math.round(value);}}
                       unit="%"
                       stroke="black"
                       label={{value: "% of patents", position: "insideBottom", dy: 50}}
                />
                <YAxis type="category" dataKey="name" stroke="black" tick={{width: 200}} />
                <Tooltip formatter={(value) => `${Math.round(value, 0)} %`}
                         cursor={false}/>
                <Legend/>
                <Bar dataKey="good" stackId="a"  barSize={40} fill={color_blue}/>
                <Bar dataKey="average" stackId="a" barSize={40}  fill={color_medium}/>
                <Bar dataKey="bad" stackId="a" barSize={40}  fill={color_red}/>
              </BarChart>
            </ResponsiveContainer>
      </React.Fragment>
  );
};

MetricsChart.propTypes = {
  metric_category: PropTypes.string,
  labels: PropTypes.object,
  patents: PropTypes.arrayOf(PropTypes.object)
};

export default MetricsChart;
