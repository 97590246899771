import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Cell,
} from 'recharts';
import {get_color_range} from '../../../../constants/Charts';
import {
    getInvestByPerformance,
    numFormatter,
} from '../../../../utils/helpers';
import BoxLayout from '../BoxLayout';


const InvestmentByPerformance = (props) => {

    let clusterQuality = (value) => {
        if (value === 0) {
            return 'low';
        } else if (value > 0 && value < 1) {
            return 'medium';
        } else if (value === 1) {
            return 'high';
        }
    };

    const {
        patents,
    } = props;
    if (patents === undefined) return;

    const colors = get_color_range(3);
    let data = getInvestByPerformance(patents);

    const title = 'Investment vs performance';
    const subtitle = 'Cluster development based on IP protection sought';
    const content = <ResponsiveContainer width="100%" style={{position: "absolute", bottom: 0}}>
        <BarChart
            data={data}
            margin={{
                right: 40,
                left: 40,
                bottom: 30,
            }}
        >
            <XAxis dataKey="classification" stroke="black"
                   label={{
                       value: 'Performance',
                       position: 'insideBottom',
                       dy: 25,
                   }}/>
            <YAxis type="number"
                   tickFormatter={numFormatter}
                   stroke="black"/>
            <Tooltip formatter={numFormatter}
                     cursor={false}/>
            <Bar dataKey="investment" name='Quality'
                 stackId="a" fill="#D43D51" unit="€"
                 barSize={40}>
                {data.map((entry, index) => {
                    let color = null;
                    switch (entry.classification || null) {
                        case 'low':
                            color = colors[0];
                            break;
                        case 'medium':
                            color = colors[1];
                            break;
                        case 'high':
                            color = colors[2];
                            break;
                    }
                    return <Cell fill={color}/>;
                })}
            </Bar>
        </BarChart>
    </ResponsiveContainer>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

InvestmentByPerformance.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default InvestmentByPerformance;
