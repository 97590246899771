import React from 'react';
import {WorldMap} from 'react-svg-worldmap';
import {
    EP_COUNTRIES,
    EP_COUNTRIES_MIN,
    ISO31662,
} from '../../../../constants/CountryData';
import {Box, Paper, Typography} from '@material-ui/core';
import {color_blue} from '../../../../constants/Charts';
import BoxLayout from '../BoxLayout';

const WorldMapC = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }

    const counts = patents.reduce((data, patent) => {
        let offices = [];
        const office = patent.meta.office;
        if (office !== undefined) {
            if (ISO31662.indexOf(office) > -1) {
                offices.push(office);
            } else if (office === 'EP') {
                offices = [].concat(offices, EP_COUNTRIES_MIN);
            }
            offices.forEach((o) => {
                const ol = o.toLowerCase();
                if (!data.hasOwnProperty(ol)) {
                    data[ol] = {country: ol, value: 0};
                }
                data[ol].value++;
            });
        }
        return data;
    }, {});

    const title = 'Country overview';
    const subtitle = 'Countries in which patents were filed';
    const content = <Box style={{textAlign: 'center', overflow: 'hidden'}}>
        <WorldMap size="responsive" color={color_blue}
                  data={Object.values(counts)}
                  borderColor="black"/>
    </Box>;
    const help = <Typography variant="body1" component="div"
                             align="justify">
        Patents can be filed in several
        countries. A European Patent (EP) is only valid in the European
        countries in which the patent owner validated it after grant.<br/>
        A worldwide patent application (WO) is only valid in the countries in
        which the patent owner filed a subsequent national
        application.</Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

export default WorldMapC;