import React from 'react';
import {WorldMap} from 'react-svg-worldmap';
import {
    EP_COUNTRIES,
    EP_COUNTRIES_MIN,
    ISO31662,
} from '../../../../constants/CountryData';
import {color_blue} from '../../../../constants/Charts';
import {Box, Paper, Typography} from '@material-ui/core';
import BoxLayout from '../BoxLayout';

const InvestmentPerCountry = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }

    const counts = patents.reduce((data, patent) => {
        let offices = [];
        const office = patent.meta.office;
        if (office !== undefined) {
            if (ISO31662.indexOf(office) > -1) {
                offices.push(office);
            } else if (office === 'EP') {
                // Do not add EP countries
                // offices = [].concat(offices, EP_COUNTRIES_MIN);
            }
            offices.forEach((o) => {
                const ol = o.toLowerCase();
                if (!data.hasOwnProperty(ol)) {
                    data[ol] = {country: ol, value: 0};
                }
                data[ol].value += patent.costs.total;
            });
        }
        return data;
    }, {});

    const title = 'Investment per country';
    const subtitle = 'Estimation of the past and future IP investment for the patents in each country. For pending European Patent applications, we assumed that the patent owner will validate the patent in Germany, France, Italy, UK and Netherlands. For pending worldwide patent applications (WO), we assumed that the patent owner will file applications in Germany, France, Italy, UK, Netherlands, US, Japan, China and Korea.';
    const content = <Box style={{textAlign: 'center'}}>
        <WorldMap size="responsive" color={color_blue}
                  data={Object.values(counts)}
                  borderColor="black"/>
    </Box>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

export default InvestmentPerCountry;