import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import _ from 'lodash';
import BoxLayout from '../BoxLayout';
import {numFormatter} from '../../../../utils/helpers';
import {color_numeric_metrics} from '../../../../constants/Charts';

const PastInvestment = (props) => {
    const {
        patents,
        reportDate,

    } = props;

    if (!patents) {
        return null;
    }

    const totalCosts = _.sumBy(patents, function(patent) {
        return (patent.costs?.office?.paid || 0) +
            (patent.costs?.attorney?.paid || 0);
    });

    const title = 'Past investment';
    const subtitle = <Typography variant="body1" component="div" align="justify">The
      past IP investment for the patents taking into account patent office
      fees and average patent attorney fees until {reportDate !== undefined
          ? (new Date(reportDate)).toDateString()
          : 'today'}.
    </Typography>;
    const content = <Typography component="p" align="center" style={{
      color: color_numeric_metrics,
      fontSize: 80,
    }}>€ {numFormatter(totalCosts)}</Typography>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

PastInvestment.propTypes = {
    reportDate: PropTypes.object,
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default PastInvestment;
