import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,

} from '@material-ui/core';
import {ExpandMore, HelpOutline} from '@material-ui/icons';

const MetricDescriptions = (props) => {
    const {
        metric_descriptions,
    } = props;

    if (!metric_descriptions) {
        return null;
    }

    let descriptions = [];
    for (const [metric, data] of Object.entries(metric_descriptions)) {
        descriptions.push(
            <React.Fragment>
                <Grid item xs={12} md={2}>
                    <Typography component="p" variant="body"
                                style={{fontWeight: 'bold'}}>
                        {data.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    {data.description}
                </Grid>
            </React.Fragment>,
        );
    }

    return (
        <Grid container direction="row" spacing={2}>
            {descriptions.map(metric_desc => (metric_desc))}
        </Grid>
    );
};

MetricDescriptions.propTypes = {
    metric_descriptions: PropTypes.object,
};

export default MetricDescriptions;