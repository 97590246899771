import React from 'react';
import PropTypes from 'prop-types';
import {
    ResponsiveContainer,
    Pie,
    PieChart,
    Cell,
    Legend,
    Tooltip,
    Label,
} from 'recharts';
import {Typography} from '@material-ui/core';
import BoxLayout from '../BoxLayout';
import {color_blue, color_red, color_green} from '../../../../constants/Charts';

const PercentCleantech = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }
    const cleantech_patents = patents.filter(
        patent => Object.keys(patent.cleantech || {}).length !== 0);

    let cleantech_dist = patents.reduce((data, patent) => {
        if (Object.keys(patent.cleantech || {}).length > 0) {
            data['cleantech']++;
        } else {
            data['no_cleantech']++;
        }
        return data;
    }, {cleantech: 0, no_cleantech: 0});
    let percentage = 0;
    if (cleantech_dist['no_cleantech'] == 0) {
        percentage = 1;
    } else {
        percentage = cleantech_dist['cleantech'] /
            (cleantech_dist['no_cleantech'] + cleantech_dist['cleantech']);
    }
    let data = [
        {name: 'Cleantech', value: cleantech_dist['cleantech']},
        {name: 'No cleantech', value: cleantech_dist['no_cleantech']}];

    const title = 'Cleantech patents';
    const subtitle = 'Percentage of innovations that reduce environmental impact';
    const content = <ResponsiveContainer width='100%' height={400}>
        <PieChart margin={{left: 40, bottom: 0, right: 40}}>
            <Legend layout="horizontal" verticalAlign="bottom" align="center"/>
            <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="150"
                isAnimationActive={true}
            >
                <Label value={(percentage * 100).toFixed(0) + '%'}
                       position="center"
                       style={{
                           textAnchor: 'middle',
                           fontSize: "5rem",
                           fill: color_green,
                       }}/>

                <Cell fill={color_green}/>
                <Cell fill={color_blue}/>
            </Pie>
            <Tooltip cursor={false}/>
        </PieChart>
    </ResponsiveContainer>;
    const help = <Typography compnent="div" variant="body2">
        Measuring environmental impact reduction innovation using patent data.<br/>
        To identify patents in environment-related technologies,
        detailed classification strategies have been developed.
        They encompass a broad spectrum of technologies related to the reduction of environmental
        pollution, water scarcity and climate change mitigation.
    </Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

PercentCleantech.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default PercentCleantech;
