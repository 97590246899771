import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button
} from '@material-ui/core';
import styles from './Intro.module.css';

const Intro = (props) => {
    const {
        funChangeSection,
        date,
        version,
        customer,
        project,
    } = props;

    return (
        <div style={{width: '100%', minHeight: '100%'}}>
            <video id="vid" video autobuffer autoPlay loop muted
                   className={styles.video_background}>
                <source id="mp4"
                        src={process.env.PUBLIC_URL +
                        '/videos/background.mp4'}
                        type="video/mp4" />
            </video>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{minHeight: '100%'}}
            >
                <Grid item xs={12}>
                    <Box pt={10} pb={10}>
                        <img alt="GoodIP Logo" className={styles.logo}
                             src={process.env.PUBLIC_URL +
                             '/imgs/logo_light.png'}/>
                    </Box>
                    <Box py={7}>
                        <Typography variant="h1" component="h1"
                                    style={{color: 'white'}} align="center">
                            {project}
                        </Typography>
                    </Box>
                    <Box py={7}>
                        <Typography variant="h4" component="h4"
                                    style={{color: 'white'}} align="center">
                            AI-driven patent evaluation for<br/>
                        </Typography>
                        <Typography variant="h2" component="h2"
                                    style={{color: 'white'}} align="center">
                            {customer}
                        </Typography>
                    </Box>
                    <Box py={7}>
                        <Typography variant="body1" style={{color: 'white'}} align="center">
                            Confidential - {(new Date(date)).toDateString()} -
                            V{version}
                        </Typography>
                    </Box>
                    <Box pt={5} pb={20} align="center">
                        <Button style={{color: "black", background: "#CDEAE5", fontSize: "1rem"}} onClick={()=>funChangeSection("executive")} variant="contained">
                            View insights
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

Intro.propTypes = {
    funChangeSection: PropTypes.func,
    date: PropTypes.string,
    version: PropTypes.string,
    customer: PropTypes.string,
    project: PropTypes.string,
};

export default Intro;