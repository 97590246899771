import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Chip
} from '@material-ui/core';

const Clusters = (props) => {
    const {
        clusters,
        active_filter,
        funUpdateFilter,
        nrPatentsSelected,
    } = props;

    if (!clusters || !active_filter) {
        return null;
    }
    return (

        <React.Fragment>
            <Box my={3}>
                <Typography component="h4" align="center" variant="h4">
                    Select clusters
                </Typography>
            </Box>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div>
                    {clusters.map(category => (
                        <Box m={1} style={{float: 'left'}}>
                            <Chip
                                label={category}
                                color={active_filter.indexOf(category) >= 0
                                    ? 'primary'
                                    : 'default'}
                                clickable
                                onClick={() => funUpdateFilter(
                                    category)}/>
                        </Box>
                    ))}
                </div>
            </div>
            <Box my={3}>
                <Typography component="div" variant="body2" align="center">
                    {nrPatentsSelected} patents selected
                </Typography>
            </Box>
        </React.Fragment>
    );
};

Clusters.propTypes = {
    active: PropTypes.string,
    clusters: PropTypes.arrayOf(PropTypes.str).isRequired,
    active_filter: PropTypes.array,
    funUpdateFilter: PropTypes.func,
    nrPatentsSelected: PropTypes.number
};

export default Clusters;
