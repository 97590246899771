import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
} from '@material-ui/core';
import {
    Cell,
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
} from 'recharts';
import {
    color_blue,
    color_medium,
    color_red,
    get_color_range,
} from '../../../../constants/Charts';
import {getPerformanceData} from '../../../../utils/helpers';
import {HighAvgLowXAxisTick} from '../ChartHelpers';
import BoxLayout from '../BoxLayout';
import {colorAIClassification} from '../../../../utils/charts';
import styles from './Models.module.css';

const ModelResults = (props) => {

    const {
        patents,
    } = props;

    const charts = [
        {
            image: '/imgs/icons/innovative.svg',
            title: 'Innovative',
            subtitle: 'Potential business impact',
            help: 'This AI model predicts whether a patent is innovative compared to similar patents in the same or related technology field. In consequence, the patent might be used by others. The GoodIP AI compares the patent to over 80,000 licensed patents.',
            data_key: 'innovative',
        },
        {
            image: '/imgs/icons/early.svg',
            title: 'Early',
            subtitle: 'Early position in tech cycle\n',
            help: 'This AI model predicts whether a patent is early in the applicable technology life cycle. The GoodIP AI compares the patent to over 100,000 proven new patents.',
            data_key: 'early',
        },
        {
            image: '/imgs/icons/protection.svg',
            title: 'Protected',
            subtitle: 'High protection level',
            help: 'This AI model predicts whether a patent is well protected (e.g., not easy to circument). The GoodIP AI compares the patent to over 40,000 patents that we asserted in litigation.',
            data_key: 'protected',
        },
    ];

    if (patents === undefined) return;

    let performance_results = getPerformanceData(patents);
    let data = [];
    ['high', 'medium', 'low'].forEach((performance_level) => {
        let entry = {performance: performance_level};
        Object.entries(performance_results).forEach(([model_name, value]) => {
            entry[model_name] = value[performance_level];
        });
        data.push(entry);
    });
    const chart_colors = get_color_range(3).reverse();
    let model_results = charts.map(chart => {
        const barchart = <ResponsiveContainer width="100%"
                                              height={(44 * 3) + 40}>
            <BarChart
                layout="vertical"
                data={data}
                barGap={2}
                margin={{
                    left: 40,
                    bottom: 30,
                    right: 40,
                }}>

                <XAxis type="number"
                       unit="%"
                       stroke="black"
                       label={{
                           value: '% of patents',
                           position: 'insideBottom',
                           dy: 50,
                       }}
                       domain={[0, 100]}
                       tickCount={3}
                       tickFormatter={(value) => {
                           return Math.round(value);
                       }}/>
                <YAxis type="category" dataKey="performance" interval={0}
                       stroke="black"/>
                <Tooltip formatter={(value) => `${Math.round(value,
                    2)} %`}
                         cursor={false}/>
                <Bar dataKey={chart.data_key} barSize={40}>
                    {data.map((entry, index) => (
                        <Cell fill={chart_colors[index]}/>))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>;
        return (
            <Grid item xs={12} lg={4}>
                <Box>
                    <img alt="Icon"
                         className={styles.model_icon}
                         src={process.env.PUBLIC_URL + chart.image}/>
                    <BoxLayout title={chart.title}
                               subtitle={chart.subtitle}
                               content={barchart}
                               helpContent={<Typography component="div" variant="body2">{chart.help}</Typography>}/>
                </Box>
            </Grid>
        );
    });

    return (
        <Grid container spacing={10}>
            {model_results}
        </Grid>
    );
};

ModelResults.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default ModelResults;
