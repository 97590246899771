import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {color_numeric_metrics} from '../../../../constants/Charts';
import BoxLayout from '../BoxLayout';

const NrPatents = (props) => {
    const {
        patents,
    } = props;
    if (patents === undefined) return;

    const title = 'Patents';
    const subtitle = 'Number of patents analyzed';
    const content = <Typography component="p" align="center" style={{
        fontSize: 80,
        color: color_numeric_metrics,
    }}>{patents.length}</Typography>;
    const help = <Typography component="div" variant="body2">
        "This indicator shows the number of inventions for which patent
        protection has been sought in a given country or region including all
        patent family members.<br/>
        A patent family is the set of all patents in different countries that
        protect the same invention.
        It is a count of registered patent applications (which does not mean
        that the patents are already approved as new and inventive by the patent
        office)."
    </Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

NrPatents.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default NrPatents;
