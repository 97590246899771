import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';
import BoxLayout from '../BoxLayout';
import {ExpandMore, ExpandLess, ChevronRight} from '@material-ui/icons';
import _ from 'lodash';
import md5 from 'crypto-js/sha256';

import {TreeView,TreeItem} from '@material-ui/lab';

import {HighAvgLowXAxisTick} from '../ChartHelpers';
import {qualityByCluster} from '../../../../utils/helpers';
import {colorAIClassification} from '../../../../utils/charts';

const simpleHash = str => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
};

const Topics = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }
    let data = patents.reduce((data, patent) => {
        if (Object.keys(patent.cleantech).length !== 0) {
            _.merge(data, patent.cleantech);
        }
        return data;
    }, {});

    const keyify = (obj, prefix = '') =>
        Object.keys(obj).reduce((res, el) => {
            if (Array.isArray(obj[el])) {
                return res;
            } else if (typeof obj[el] === 'object' && obj[el] !== null) {
                return [...res, ...keyify(obj[el], prefix + el + '.')];
            }
            return [...res, prefix + el];
        }, []);

    function getTree(obj, item_name, lvl) {
        let subtree = [];
        let no_children = true;
        let node_id = null;
        for (const item in obj) {
            if (typeof obj[item] === 'object') {
                no_children = false;
                subtree.push(getTree(obj[item], item, lvl+1));
            }
        }
        if(lvl === 0) {
            node_id = "root";
        } else {
            node_id = `${lvl}+${md5(item_name)}`;
        }
            return <TreeItem nodeId={node_id} label={`${no_children === true?"- ":""}${item_name}`}>
                {subtree}
            </TreeItem>;
    };

    const tree_dom = getTree(data, "Cleantech", 0);

    const title = 'Clean technologies';
    const subtitle = 'Cluster development based on IP protection sought';
    const content = <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        defaultExpanded={["root"]}
        disableSelection={true}
    >
        {tree_dom}
    </TreeView>;
    const help = <Typography variant="body2" component="div">List of technologies that reduce negative environmental impacts through significant energy efficiency improvements, the sustainable use of resources, or environmental protection activities.</Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
    );
};

Topics.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default Topics;
