import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';
import BoxLayout from '../BoxLayout';
import _ from "lodash";
import {numFormatter} from '../../../../utils/helpers';
import {color_numeric_metrics} from '../../../../constants/Charts';

const FutureInvestment = (props) => {
  const {
    patents,
  } = props;

  if (!patents) {
    return null;
  }

  const totalCosts = _.sumBy(patents, function (patent) {
    return (patent.costs?.office?.future || 0) + (patent.costs?.attorney?.future || 0);
  });

  const title = 'Future investment';
  const subtitle = 'The future IP investment for the patents taking into account patent office fees and average patent attorney fees until expiration of all patents';
  const content = <Typography component="p" align="center" style={{color: color_numeric_metrics, fontSize: 80}}>€ {numFormatter(totalCosts)}</Typography>;
  const help = null;

  return (
      <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
  );
};

FutureInvestment.propTypes = {
  patents: PropTypes.arrayOf(PropTypes.object),
};

export default FutureInvestment;
