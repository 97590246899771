import React from 'react';
import {Switch} from 'react-router';

/* Layouts */
import DefaultLayoutRoute from './containers/Layout/Default';

/* Pages */
import ReportViewContainer from './containers/Report/View';

/* Static */
import IndexPage from './components/Static/Index';

//import IndexPage from './components/';
// import About from './components/Static/About';
// import LegalNoticePage from './components/Static/LegalNotice';
// import PrivacyPage from './components/Static/Privacy';
// import Tos from './components/Static/Tos';
// import NotFound from './components/Static/NotFound';
// import Error from './components/Static/Error';
// import Thanks from './components/Static/Thanks';

export default (
    <Switch>
      <DefaultLayoutRoute path="/" exact  is_index={true} component={IndexPage}/>
      {/*<DefaultLayoutRoute path="/team/create" component={TeamCreateContainer}/>*/}
      <DefaultLayoutRoute path="/projects/:project_id/report/:access_token" component={ReportViewContainer}/>
      {/*<DefaultLayoutRoute path="/team/:hash/analysis" component={AnalysisContainer}/>*/}
      {/*<DefaultLayoutRoute path="/team/:hash/questionnaires/create" component={QuestionaireCreateContainer}/>*/}
      {/*<DefaultLayoutRoute path="/about" component={About}/>*/}
      {/*<DefaultLayoutRoute path="/legal" component={LegalNoticePage}/>*/}
      {/*<DefaultLayoutRoute path="/privacy" component={PrivacyPage}/>*/}
      {/*<DefaultLayoutRoute path="/tos" component={Tos}/>*/}
      {/*<DefaultLayoutRoute path="/advice/:user_hash/optout" component={AdviceOptoutContainer}/>*/}
      {/*<DefaultLayoutRoute path="/advice/own/:user_hash" component={AdviceListContainer}/>*/}
      {/*<DefaultLayoutRoute path="/advice/:user_hash/:team_hash/:question_alias" component={AdviceCreateContainer}/>*/}
      {/*<DefaultLayoutRoute path="/oops" component={Error}/>*/}
      {/*<DefaultLayoutRoute path="/thanks" component={Thanks}/>*/}
      {/*<DefaultLayoutRoute component={NotFound}/>*/}
    </Switch>
);