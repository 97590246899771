import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid,
  Paper,
  Typography,

} from '@material-ui/core';
import MetricsChart from './MetricsChart';
import MetricDescriptions from '../MetricDescriptions';
import BoxLayout from '../BoxLayout';

const Innovative = (props) => {
  const {
    patents,
  } = props;

  if (!patents) {
    return null;
  }

  const labels_innovative = {
    dev_ind_claims: {
      name: 'Unchanged independent claims',
      description: 'Illustrates the textual changes between the independent claims in a published patent application and in the granted patent',
    },
    dev_first_claim: {
      name: 'Unchanged first claim',
      description: 'Illustrates the similarity and textual changes between the first claims in a published patent application and in the granted patent',
    },
    citations_3yrs: {
      name: 'Others citing patent',
      description: 'Classifies the number of times a patent is cited in other patents in the first 3 years after its publication',
    },
    citations_3yrs_exam: {
      name: 'Examiner citing patent',
      description: 'Classifies the normalized number of times a patent is cited by an examiner in the first 3 years after its publication',
    },
  };

  const title = 'Innovativ';
  const subtitle = 'Normalized metrics indicative of innovative patents such as licensed patents';
  const content = <MetricsChart patents={patents} labels={labels_innovative}
                                metric_category="innovativeness"/>;
  const help = <MetricDescriptions metric_descriptions={labels_innovative}/>;

  return (
      <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
  );
};

Innovative.propTypes = {
  patents: PropTypes.arrayOf(PropTypes.object),
};

export default Innovative;
