import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Grid,
    Typography,
    Chip,
} from '@material-ui/core';
import {color_numeric_metrics} from '../../../../constants/Charts';
import BoxLayout from '../BoxLayout';

const NrClusters = (props) => {
    const {
        clusters,
    } = props;
    if (clusters === undefined) return;

    const title = 'Clusters';
    const subtitle = 'Number of clusters covered';
    const content = <React.Fragment>
      <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
      >
            <Grid item xs={12} md={3}>
                <Typography component="p" align="center"
                            style={{
                                fontSize: 80,
                                color: color_numeric_metrics,
                            }}>
                  {clusters.length}
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box p={3} textAlign="left">
                    {clusters.map(
                        (cluster) => <Chip component="span" label={cluster}
                                           style={{
                                               margin: '4px',
                                               color: 'black',
                                           }}/>)}
                </Box>
            </Grid>
        </Grid>
    </React.Fragment>;
    const help = <React.Fragment></React.Fragment>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

NrClusters.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default NrClusters;
