import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import {get_color_range} from '../../../../constants/Charts';
import {numFormatter} from '../../../../utils/helpers';
import BoxLayout from '../BoxLayout';

const RegionalInvestment = (props) => {

    const {
        patents,
    } = props;
    const colors = get_color_range(4);
    if (patents === undefined) return;

    const data = patents.reduce((data, patent) => {
        const office = patent.meta.office;
        if (office != null) {
            if (!data.hasOwnProperty(office)) {
                data[office] = {
                    office: office,
                    office_past: 0,
                    office_future: 0,
                    attorney_past: 0,
                    attorney_future: 0,
                };
            }
            try {
                const cost_obj = patent.costs;
                const office_past = Number(cost_obj.office.paid);
                const office_future = Number(cost_obj.office.future);
                const attorney_past = Number(cost_obj.attorney.paid);
                const attorney_future = Number(cost_obj.attorney.future);
                if (!isNaN(office_past))
                    data[office].office_past += office_past;
                if (!isNaN(office_future))
                    data[office].office_future += office_future;
                if (!isNaN(attorney_past))
                    data[office].attorney_past += attorney_past;
                if (!isNaN(attorney_future))
                    data[office].attorney_future += attorney_future;
            } catch (e) {

            }
        }
        return data;
    }, {});

    let data_vals = Object.keys(data).map(function(key) {
        let obj = data[key];
        obj.total = obj.office_past + obj.office_future + obj.attorney_past +
            obj.attorney_future;
        return obj;
    });

    data_vals.sort((a, b) => (a.total > b.total) ? -1 : 1);

    data_vals = data_vals.filter(office => office.total > 0);

    const title = 'Regional investment';
    const subtitle = 'Regional cost breakdown';
    const content = <ResponsiveContainer width="100%" aspect={2 / 1}>
        <BarChart
            data={data_vals}
            margin={{
                right: 40,
                left: 40,
                bottom: 30,
            }}
        >
            <XAxis dataKey="office" interval={0} label={{
                value: 'Office',
                position: 'insideBottom',
                dy: 50,
            }} stroke="black" tic/>
            <YAxis type="number" tickFormatter={numFormatter}
                   stroke="black"/>
            <Tooltip formatter={numFormatter} cursor={false}/>
            <Legend/>
            <Bar dataKey="office_past" name="Office costs paid"
                 stackId="a" fill={colors[0]} unit="€" barSize={40}/>
            <Bar dataKey="attorney_past"
                 name="Attorney costs paid" stackId="a"
                 fill={colors[1]} unit="€" barSize={40}/>
            <Bar dataKey="office_future"
                 name="Office costs future" stackId="a"
                 fill={colors[2]} unit="€" barSize={40}/>
            <Bar dataKey="attorney_future"
                 name="Attorney costs future" stackId="a"
                 fill={colors[3]} unit="€" barSize={40}/>
        </BarChart>
    </ResponsiveContainer>;
    const help = null;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

RegionalInvestment.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default RegionalInvestment;
