import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Grid,
    LinearProgress,
} from '@material-ui/core';

import ExecutiveSummary from './ExecutiveSummary/ExecutiveSummary';
import Intro from './Intro/Intro';
import Investment from './Investments/Investment';
import Performance from './Performance/Performance';
import Metrics from './Metrics/Metrics';
import Cleantech from './Cleantech/Cleantech';
import AllPatents from './AllPatents/AllPatents';
import Background from '../../Static/Background';
import ClusterFilter from '../../../containers/Report/ClusterFilter';
import {filterInsights} from '../../../utils/helpers';

const View = (props) => {

    const {
        section,
        funChangeSection,
        report,
        loading,
    } = props;

    const styles = theme => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    });

    if (!report || loading) {
        return (
            <LinearProgress/>
        );
    }

    if (section === null) {
        return (
            <React.Fragment>
                <Intro funChangeSection={funChangeSection}
                       date={report.date}
                       version={report.version}
                       customer={report.meta?.customer}
                       project={report.meta?.project_name}
                />
            </React.Fragment>
        );
    }

    let date_formatted = new Date(report.date);
    return (
        report ?
            <React.Fragment>
                <Grid container direction="row"
                      alignItems="flex-start"
                      justify="space-between"
                      spacing={4}
                >
                    {(section === 'executive') &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <ExecutiveSummary
                                funChangeSection={funChangeSection}
                                meta={report.meta}
                                clusters={report.meta.clusters}
                                patents={report.patents}
                                insights={report.meta.insights}
                            />
                        </Grid>
                    </React.Fragment>
                    }
                    {/*section === "overview" &&
              <React.Fragment>
                <Grid item xs={12}>
                <Overview funChangeSection={funChangeSection} patents={report.patents} clusters={report.meta.clusters}/>
                </Grid>
              </React.Fragment>
              */}
                    {section === 'investments' &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <ClusterFilter patents={report.patents}
                                           clusters={report.meta.clusters}>
                                <Investment funChangeSection={funChangeSection}
                                            insights={filterInsights(
                                                report.meta.insights,
                                                'investment')}
                                            reportDate={report.date}
                                />
                            </ClusterFilter>
                        </Grid>
                    </React.Fragment>
                    }
                    {section === 'ai_evaluation' &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <ClusterFilter patents={report.patents}
                                           clusters={report.meta.clusters}>
                                <Performance funChangeSection={funChangeSection}
                                             insights={filterInsights(
                                                 report.meta.insights,
                                                 'performance')}/>
                            </ClusterFilter>
                        </Grid>
                    </React.Fragment>
                    }
                    {section === 'cleantech' &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <ClusterFilter patents={report.patents}
                                           clusters={report.meta.clusters}>
                                <Cleantech funChangeSection={funChangeSection}
                                           insights={filterInsights(
                                               report.meta.insights,
                                               'cleantech')}/>
                            </ClusterFilter>
                        </Grid>
                    </React.Fragment>
                    }
                    {section === 'metrics' &&
                    <Grid item xs={12}>
                        <ClusterFilter patents={report.patents}
                                       clusters={report.meta.clusters}>
                            <Metrics funChangeSection={funChangeSection}/>
                        </ClusterFilter>
                    </Grid>
                    }
                    {section === 'patents' &&
                    <Grid item xs={12}>
                        <AllPatents patents={report.patents}
                                    funChangeSection={funChangeSection}/>
                    </Grid>
                    }
                    {section === 'background' &&
                    <Grid item xs={12}>
                        <Background funChangeSection={funChangeSection}/>
                    </Grid>
                    }
                </Grid>
                <Box pt={10}>
                    <Typography variant="body2" style={{color: '#4a4a4a'}}
                                align="center">
                        Confidential - {(new Date(
                        report.date)).toDateString()} - V{report.version}
                    </Typography>
                </Box>
            </React.Fragment>
            : null
    );
};

View.propTypes = {
    section: PropTypes.string,
    report: PropTypes.object,
    funChangeSection: PropTypes.func,
    loading: PropTypes.bool,
};

export default View;
