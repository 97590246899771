import axios from 'axios';
import {
  API_URL,
  API_VERSION,
  API_ROUTE_REPORT, API_ROUTE_PROJECTS,
} from '../constants/GoodIPAPI';

export async function apiReportGet(project_id, access_token) {
  const url = API_URL + API_ROUTE_PROJECTS + '/' + project_id + API_ROUTE_REPORT;
  const parameters = {params: {token: access_token}};
  const response = await axios.get(url, parameters);
  return response.data;
}