import React from 'react';
import {Text} from 'recharts';


export const HighAvgLowXAxisTick = props => {
    const min = props.min;
    const max = props.max;
    const average = (min + max) /2;
    let value = null;
    if(props.payload.value === min) {
        value = "Low";
    } else if (props.payload.value === average) {
        value = "Average";
    } else if (props.payload.value === max) {
        value = "High";
    }
    return <Text {...props}>{value}</Text>
};