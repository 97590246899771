import {createStore, compose, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createLogger} from 'redux-logger';
/* Middleware */
import thunk from 'redux-thunk';

/* Reducers */
import rootReducer from './reducers';

const debugware = [];
if (process.env.NODE_ENV !== 'production') {
  debugware.push(createLogger({
    collapsed: true,
  }));
}

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    //reducer,
    persistedReducer,
    compose(
        applyMiddleware(thunk, ...debugware),
        typeof window === 'object' &&
        typeof window.devToolsExtension !== 'undefined'
            ? window.devToolsExtension()
            : f => f,
    ),
);

export const persistor = persistStore(store);