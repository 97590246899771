import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
    Cell
} from 'recharts';
import {HighAvgLowXAxisTick} from '../ChartHelpers';
import {qualityByCluster, filterExpired} from '../../../../utils/helpers';
import {colorAIClassification} from '../../../../utils/charts';
import BoxLayout from '../BoxLayout';

const IPPerformance = (props) => {
  const {
    patents,
  } = props;
  if (!patents) {
    return null;
  }
  const activePatents = filterExpired(patents);
  const cluster_quality = qualityByCluster(activePatents);
  let chart_data = [];
  for (const [key, value] of Object.entries(cluster_quality)) {
    chart_data.push({name: key, quality: value});
  }
  chart_data.sort((a, b) => (a.quality > b.quality) ? -1 : 1);

  const title = "IP performance";
  const subtitle = "AI-driven patent performance per cluster";
  const content = <ResponsiveContainer width='100%' height={44*chart_data.length+140}>
    <BarChart data={chart_data}
              layout="vertical"
              margin={{left: 80, bottom: 30, right: 40}}>
      <XAxis type="number"
             stroke="black"
             label={{value: "Performance", position: "insideBottom", dy: 15}}
             tickCount={3}
             domain={[0, 1]}
             tick={<HighAvgLowXAxisTick min={0} max={1}/>}
      />
      <YAxis type="category" dataKey="name" interval={0} stroke="black"/>
      <Bar dataKey="quality" barSize={40} fill="#42537C">
        {chart_data.map((entry, index) => (
            <Cell fill={colorAIClassification(entry.quality)}/>))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>;
  const help = <Typography component="div" variant="body2">
    This chart shows the IP rating of each cluster.<br/>
    IP performance is an indicator of patent quality, which is calculated by the AI.
    If categories are in the low range, these clusters bear risks.
  </Typography>;

  return (
      <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
  );
};

IPPerformance.propTypes = {
  patents: PropTypes.arrayOf(PropTypes.object),
};

export default IPPerformance;
