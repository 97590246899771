import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';

const Insights = (props) => {
  const {
    insights,
  } = props;

  if (!insights) {
    return null;
  }
  /*const groupedByCategory = insights.reduce((hash, obj) => ({
    ...hash,
    [obj['category']]: (hash[obj['category']] || []).concat(obj),
  }), {});*/

  //const insight_elements = Object.entries(groupedByCategory)
  let insight_elements = insights.map(insight => (
         <Grid item xs={12} lg={4}>
             <Paper style={{height: "100%", borderRadius: "20px", background: '#1f263e'}} elevation={0}>
             <Box p={3}>
              <Typography component="h5" variant="h4" gutterBottom style={{color:'white'}}>{insight.title}</Typography>
                 <Box pt={2}>
                    <Typography component="p" style={{color:'white'}}>{insight.content}</Typography>
                 </Box>
             </Box>
         </Paper>
        </Grid>
    )
  );

  return (
      <React.Fragment>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={10}
            >
              {insight_elements}
            </Grid>
      </React.Fragment>
  );
};

Insights.propTypes = {
  insights: PropTypes.arrayOf(PropTypes.object),
};

export default Insights;
