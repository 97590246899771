import React from 'react';
import {color_red, color_blue, get_color_range} from '../constants/Charts';

let colorAIClassification = (value) => {
    const colors = get_color_range(3);
    if (value >= 0 && value < 0.25) {
        return colors[0];
    }
    if (value >= 0.25 && value < 0.50) {
        return colors[1];
    }
    if (value >= 0.50 && value <= 1) {
        return colors[2];
    }
    return null;
};

export {colorAIClassification};