import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Grid,
    Paper,
} from '@material-ui/core';
import HelpSection from './HelpSection';

const BoxLayout = (props) => {
    const {
        title,
        subtitle,
        content,
        helpContent,
    } = props;

    if (!content) {
        return null;
    }

    return (
        <Paper elevation={0} style={{height: '100%', borderRadius: '20px'}}>
            <Box display="flex"
                 flexDirection="column"
                 justifyContent="space-between"
                 alignItems="stretch"
                 style={{height: '100%'}}>
                <Box px={3} pt={3}>
                    <Typography component="h4" variant="h4"
                                style={{color: '#1f263e'}}>
                        {title}
                    </Typography>
                    <Box color="#4a4a4a" my={1}>
                        <Typography variant="body1" component="div">
                            {subtitle}
                        </Typography>
                    </Box>
                </Box>

                <Box p={3}
                     flexGrow={1}
                     style={{
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'center',
                     }}>
                    {content}
                </Box>
                {helpContent !== null &&
                <Box p={1} pb={1}>
                    <HelpSection children={helpContent}/>
                </Box>}
            </Box>
        </Paper>
    );
};

BoxLayout.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.object,
    helpContent: PropTypes.object,
};

export default BoxLayout;