import React from 'react';
import PropTypes from 'prop-types';
import {ArrowBackIos, ArrowForwardIos} from '@material-ui/icons';
import ScrollToTop from '../../../utils/scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    Box,
    Paper,
    Typography,
    Button,
    Link,
} from '@material-ui/core';
import {Info} from '@material-ui/icons';
import {color_blue, color_medium, color_red} from '../../../constants/Charts';

const Pagination = (props) => {
    let {
        funChangeSection,
        next,
        labelNext,
        previous,
        labelPrevious
    } = props;

    return (
        <Box display="flex" flexDirection="row" justifyContent="center" p={3}>
            {previous != null && <Button
                variant="contained"
                color="primary"
                style={{margin: "5px"}}
                onClick={()=>{funChangeSection(previous)}}
                startIcon={<ArrowBackIos>{labelPrevious}</ArrowBackIos>}
            >
                {labelPrevious}
            </Button>}
            {next != null && <Button
                variant="contained"
                color="primary"
                style={{margin: "5px"}}
                onClick={()=>{funChangeSection(next)}}
                endIcon={<ArrowForwardIos>{labelNext}</ArrowForwardIos>}
            >
                {labelNext}
            </Button>}
        </Box>
    );
};

Pagination.propTypes = {
    funChangeSection: PropTypes.func.isRequired,
    next: PropTypes.string,
    labelNext: PropTypes.string,
    previous: PropTypes.string,
    labelPrevious: PropTypes.string,
};

export default Pagination;
