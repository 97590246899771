import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
} from '@material-ui/core';

import Clusters from '../Clusters';
import CleantechTopicDistribution from './CleantechTopicDistribution';
import PercentCleantech from '../ExecutiveSummary/PercentCleantech';

import List from '../List';
import Insights from '../Insights';
import Topics from './Topics';
import CleantechFilingDate from './CleantechFilingDate';
import Pagination from '../Pagination';


const Cleantech = (props) => {
    const {
        patents,
        insights,
        clusters,
        active_filter,
        funUpdateFilter,
        funChangeSection
    } = props;

    if (!patents) {
        return null;
    }

    const cleantech_patents = patents.filter(patent => Object.keys(patent.cleantech || {}).length !== 0);

        return (
        <React.Fragment>
            <Box pb={5}>
                <Typography component="h3" variant="h3" gutterBottom>
                    Cleantech
                </Typography>
            </Box>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                spacing={10}
            >
                <Grid item xs={12}>
                    <Insights insights={insights}/>
                </Grid>
                <Grid item xs={12}>
                    <Clusters clusters={clusters} active_filter={active_filter}
                              funUpdateFilter={funUpdateFilter} nrPatentsSelected={patents.length}/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CleantechTopicDistribution patents={cleantech_patents}/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Topics patents={cleantech_patents} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <CleantechFilingDate cleantech_patents={cleantech_patents} patents={patents}/>
                </Grid>
                <Grid item xs={12}>
                    <List patents={cleantech_patents} visible_columns={['cleantech']}/>
                </Grid>
                <Grid item xs={12}>
                    <Pagination funChangeSection={funChangeSection}
                                previous="metrics" labelPrevious="Metrics"
                                next="patents" labelNext="All Patents" />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

Cleantech.propTypes = {
    funChangeSection: PropTypes.func,
    patents: PropTypes.arrayOf(PropTypes.object),
    insights: PropTypes.arrayOf(PropTypes.object),
    clusters: PropTypes.array,
    active_filter: PropTypes.array,
    funUpdateFilter: PropTypes.func,
};

export default Cleantech;
