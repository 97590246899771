import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  CssBaseline,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
/* UI Components */
import LandingPageLayout from './LandingPageLayout';
import AppLayout from './AppLayout';
/* GA */
//import {Tracking} from '../Tracking';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#F0F0F7',
    flexGrow: 1,
    zIndex: 1,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#F0F0F7',
    paddingTop: theme.spacing(7),
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(7),
    /*padding: theme.spacing(3),*/
    //marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    overflow: 'hidden',
  },
  content_index: {
    flexGrow: 1,
    //paddingTop: theme.spacing(3),
    /*padding: theme.spacing(3),*/
    //marginTop: theme.spacing(8),
    overflow: 'hidden',
  },
  mainContainer: {
    marginBottom: theme.spacing(15),
  },
  appBar: {
    position: 'absolute',
    backgroundColor: '#f5f6f7',
    color: theme.primary,
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },

  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    textAlign: 'center',
  },
  logo: {
    maxWidth: drawerWidth,
    maxHeight: '40px',
    marginTop: '12px',
    display: 'inline-block',

  },
  fullHeight: {
    background: 'red',
    minHeight: '100vh',
  },
  drawerRoot: {
    height: '100%',
  },
  drawerPaper: {
    color: '#dfdfdf',
    backgroundColor: '#1f263e',
    //backgroundColor: '#fefefe',
    width: drawerWidth,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerBottomSection: {
    paddingBottom: '12px',
    width: '100%',
  },
});

const DefaultLayoutView = (props) => {

  const {
    theme,
    classes,
    is_index,
    drawerToggleFun,
    drawerState,
      section,
      funChangeSection,
  } = props;

  return (
      <div className={classes.root}>
        <CssBaseline/>
          <AppLayout classes={classes}
                     is_index={is_index}
                     theme={theme}
                     section={section}
                     funChangeSection={funChangeSection}
                     drawerToggleFun={drawerToggleFun}
                     drawerState={drawerState}
              >
            {props.children}
          </AppLayout>
      </div>);
};

DefaultLayoutView.propTypes = {
  section: PropTypes.string,
  funChangeSection: PropTypes.func.isRequired,
  is_index: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
  theme: PropTypes.object,
  drawerToggleFun: PropTypes.func.isRequired,
  drawerState: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    teams: state.teamReducer.teams
  };
};

//const DefaultLayoutStyled = connect(mapStateToProps)(
//    withStyles(styles, {withTheme: true})(DefaultLayoutView)
//);

const DefaultLayoutStyled =
    withStyles(styles, {withTheme: true})(DefaultLayoutView);

export default DefaultLayoutStyled;