import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    ResponsiveContainer,
    Pie,
    PieChart,
    Cell,
    Tooltip, BarChart,
} from 'recharts';
import BoxLayout from '../BoxLayout';
import {
    getStatusDistribution, numFormatter,
} from '../../../../utils/helpers';
import {get_color_range} from '../../../../constants/Charts';

const PatentsByStatus = (props) => {
    const {
        patents,
    } = props;

    if (!patents) {
        return null;
    }
    const data = getStatusDistribution(patents);
    let chartData = [];
    Object.entries(data).forEach(([status, percentage]) => {
        chartData.push({name: status, value: percentage});
    });
    const colors = get_color_range(chartData.length);

    const title = 'IP status';
    const subtitle = 'Current status of the patents';
    const content = <ResponsiveContainer width='100%' height={350}>
        <PieChart margin={{left: 40, bottom: 30, right: 40}}>
            <Pie
                dataKey="value"
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="150"
                isAnimationActive={true}
                label={(entry) => entry.name}

            >
                {chartData.map((entry, index) => {
                    let color = null;
                    switch (entry.name || null) {
                        case 'granted':
                            color = colors[2];
                            break;
                        case 'applications':
                            color = colors[1];
                            break;
                        case 'expired':
                            color = colors[0];
                            break;
                    }
                    return <Cell fill={color}/>;
                })}
            </Pie>
            <Tooltip cursor={false}/>
        </PieChart>
    </ResponsiveContainer>;
    const help = <Typography component="div" variant="body2">
        After the patent owner files the patent application,
        the patent office grants the patent or rejects it. A
        patent expires 20 years after the date of
        application unless the patent owner stops paying the
        maintenance fees for it or a court invalidates the
        patent.
    </Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

PatentsByStatus.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default PatentsByStatus;
