import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    Grid,
} from '@material-ui/core';
import {
    AreaChart,
    BarChart,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts';
import _ from 'lodash';

import {get_color_range} from '../../../../constants/Charts';
import BoxLayout from '../BoxLayout';

const ClusterDistribution = (props) => {

    const {
        patents,
    } = props;
    if (patents === undefined) return;

    let {years, clusters} = patents.reduce((data, patent) => {
        const application = parseInt(patent.dates.application.split('-')[0]);
        const expiration = parseInt(
            patent.dates.adjusted_expiration.split('-')[0]);
        if (!isNaN(application) && !isNaN(expiration)) {
            patent.meta.clusters.forEach(cluster => {
                if (!data.clusters.includes(cluster)) {
                    data.clusters.push(cluster);
                }
                _.range(application, expiration + 1).forEach(function(year) {
                    if (!data.years.hasOwnProperty(year)) {
                        data.years[year] = {name: year};
                    }
                    if (!data.years[year].hasOwnProperty(cluster)) {
                        data.years[year][cluster] = 0;
                    }
                    data.years[year][cluster]++;
                });
            });
        }
        return data;
    }, {years: {}, clusters: []});

    var cluster_development_vals = Object.keys(years).map(function(key) {
        return years[key];
    });

    cluster_development_vals.sort((a, b) => (a.count > b.count) ? -1 : 1);

    const colors = get_color_range(clusters.length).reverse();

    /*var clusters = patents.reduce((clusters, patent) => {
      patent.meta.clusters.forEach(cluster => {
        if (!clusters.hasOwnProperty(cluster)) {
          clusters[cluster] = {name: cluster, count: 0}
        }
        clusters[cluster]['count']++;
      });
      return clusters;
    }, {});

    var clusters = patents.reduce((clusters, patent) => {
      patent.meta.clusters.forEach(cluster => {
        if (!clusters.hasOwnProperty(cluster)) {
          clusters[cluster] = {name: cluster, count: 0}
        }
        clusters[cluster]['count']++;
      });
      return clusters;
    }, {});



    var cluster_values = Object.keys(clusters).map(function(key) {
      return clusters[key];
    });

    cluster_values.sort((a, b) => (a.count > b.count) ? -1 : 1);
  */

    const title = 'Cluster development';
    const subtitle = 'Cluster development based on IP protection sought';
    const content = <ResponsiveContainer width='100%' aspect={5 / 1}>
        <BarChart
            width={500}
            height={400}
            data={cluster_development_vals}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
            }}
        >
            <CartesianGrid vertical={false}
                           strokeDasharray="3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>


            {clusters.map((cluster, idx) => (
                <Bar type="monotone" dataKey={cluster}
                     fill={colors[idx]}
                     stroke={colors[idx]} stackId="1"/>
            ))}
        </BarChart>
    </ResponsiveContainer>;
    const help = <Typography variant="body2" component="div">The aggregated
        development per cluster is based on the priority date of each patent
        application filed by the patent owner.</Typography>;

    return (
        <BoxLayout title={title} subtitle={subtitle} content={content}
                   helpContent={help}/>
    );
};

ClusterDistribution.propTypes = {
    patents: PropTypes.arrayOf(PropTypes.object),
};

export default ClusterDistribution;
