import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Grid,
} from '@material-ui/core';

import Clusters from '../Clusters';
import Insights from '../Insights';
import ModelResults from './ModelResults';
import RegionalIPPerformance from './RegionalIPPerformance';
import PerformanceOverTime from './PerformanceOverTime';
import PerformanceByCluster from './PerformanceByCluster';

import Models from './Models';
import List from '../List';
import Pagination from '../Pagination';

const Performance = (props) => {
    const {
        patents,
        insights,
        clusters,
        active_filter,
        funUpdateFilter,
        funChangeSection
    } = props;

    if (!patents) {
        return null;
    }
    const cols_to_show = [
        'ai_classification_innovative',
        'ai_classification_early',
        'ai_classification_protected'];

    return (
        <React.Fragment>
            <Box pb={5}>
                <Typography component="h3" variant="h3"
                            gutterBottom>AI Evaluation</Typography>
            </Box>
            <Box pb={5}>
                <Typography component="h4" variant="h4" gutterBottom>
                    Results of the GoodIP AI models
                </Typography>
            </Box>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                spacing={10}
            >
                <Grid item xs={12}>
                    <Insights insights={insights}/>
                </Grid>
                <Grid item xs={12}>
                    <Clusters clusters={clusters} active_filter={active_filter}
                              funUpdateFilter={funUpdateFilter} nrPatentsSelected={patents.length}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <ModelResults patents={patents} model_name="innovative"/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <PerformanceOverTime patents={patents}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <PerformanceByCluster patents={patents}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <RegionalIPPerformance patents={patents}/>
                </Grid>
                <Grid item xs={12}>
                    <List patents={patents} visible_columns={cols_to_show}/>
                </Grid>
                <Grid item xs={12}>
                    <Pagination funChangeSection={funChangeSection}
                                previous="executive" labelPrevious="Executive Summary"
                                next="investments" labelNext="IP Investments" />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

Performance.propTypes = {
    funChangeSection: PropTypes.func,
    patents: PropTypes.arrayOf(PropTypes.object),
    insights: PropTypes.arrayOf(PropTypes.object),
    clusters: PropTypes.array,
    active_filter: PropTypes.array,
    funUpdateFilter: PropTypes.func,
};

export default Performance;
