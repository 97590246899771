import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';
import MetricsChart from './MetricsChart';
import MetricDescriptions from '../MetricDescriptions';
import BoxLayout from '../BoxLayout';

const Protected = (props) => {
  const {
    patents
  } = props;

  if (!patents ) {
    return null;
  }

  const labels_protection = {
    ind_claims: {name: 'Broad independent claims', description: 'Measures the narrow or negative words in a patent\'s independent claims'},
    first_claim: {name: 'Broad first claim', description: 'Measures the narrow or negative words in a patent\'s first claim'},
    underst_descr: {name: 'Understandable description', description: 'Evaluates the readability of the patent\'s description'},
    underst_first_claim: {name: 'Understandable first claim', description: 'Evaluates the readability of the patent\'s first claim'},
    compr_figure_details: {name: 'Comprehensive figures', description: 'Evaluates the number of details in the patent\'s figures'},
    nr_ind_claims: {name: 'No. independent claims', description: 'Classifies the number of independent claims to account for differences in technology fields'},
    nr_dep_claims: {name: 'No. dependent claims', description: 'Classifies the number of dependent claims to account for differences in technology fields'},
    neg_words_desc: {name: 'Negative words description', description: 'Classifies the negative words in the patent\'s description'},
  };

  const title = 'Protected';
  const subtitle = 'Normalized metrics indicative of well protected patents such as litigation patents';
  const content = <MetricsChart patents={patents} labels={labels_protection}
                                metric_category="protection"/>;
  const help = <MetricDescriptions metric_descriptions={labels_protection} />;

  return (
      <BoxLayout title={title} subtitle={subtitle} content={content} helpContent={help}/>
  );
};

Protected.propTypes = {
  metric_category: PropTypes.string,
  labels: PropTypes.object,
  patents: PropTypes.arrayOf(PropTypes.object)
};

export default Protected;
