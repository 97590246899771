import React from 'react';
import PropTypes from 'prop-types';
import TitleComponent from '../TitleComponent';
import styles from './Index.module.css';
import {
  Typography,
  Grid,
  Button,
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Hidden,
  Paper,
} from '@material-ui/core';
import {LinkedIn, Twitter} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

const direct_styles = theme => ({
  float_up: {[theme.breakpoints.down('md')]: {order: 1}},
  float_down: {[theme.breakpoints.down('md')]: {order: 2}},
  xs_center: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  minHeightSmall: {[theme.breakpoints.down('md')]: {minHeight: '150px'}},
});

const Index = (props) => {
  return (
      <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            className={styles.indexContainer}
      >
        <TitleComponent title={''}/>

        {/* First line */}
      </Grid>
  );
};

Index.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(direct_styles)(Index);
