import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ReportView from '../../components/Report/View';
import _ from "lodash";

import {
  apiReportGet
} from '../../api/GoodIPClient';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      report: null,
      access_token: null,
    };
  }

  loadData = (project_id, access_token) => {
    this.setState({loading: true});
    Promise.all([
      apiReportGet(project_id, access_token)
    ]).then(([report]) => {
      //this.props.addTeamToStore(team);
      // remove duplicates
      let patents = _.uniqBy(report.patents, function (patent) {
        return patent.meta.patent_id;
      });
      patents.map((patent) => {
        patent['id'] = patent.meta.patent_id
      });
      report.patents = patents;
      this.setState({
        ...this.state,
        'report': report,
        'access_token': access_token,
      });
    }).catch((reportGetError) => {
      if (reportGetError != null) {
        //this.props.removeTeamFromStore(hash, this.props.history, '/404');
      }
    }).finally(() => {
      this.setState({loading: false});
    });
  };

  componentDidMount() {
    const {project_id, access_token} = this.props.match.params;
    this.loadData(project_id, access_token);
  }

  render() {
    return (
        <ReportView section={this.props.section} funChangeSection={this.props.funChangeSection} report={this.state.report}
                  loading={this.state.loading}
        />
    );
  }

}

View.propTypes = {
  funChangeSection: PropTypes.func,
  location: PropTypes.object,
  //addTeamToStore: PropTypes.func.isRequired,
  //removeTeamFromStore: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      project_id: PropTypes.string.isRequired,
      access_token: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(View);